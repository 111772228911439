import React, { useState } from "react";
import car1 from "../../Images/products/car1.png";
import car2 from "../../Images/products/car2.png";
import car3 from "../../Images/products/car3.png";

function CustomCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      src: car1,
      alt: "Slide 1",
      caption: "Description for slide 1",
    },
    {
      src: car2,
      alt: "Slide 2",
      caption: "Description for slide 2",
    },
    {
      src: car3,
      alt: "Slide 3",
      caption: "Description for slide 3",
    },
  ];

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="container my-4">
      <div>
        <h6
          className="display-3 fw-bold text-center py-3"
          style={{ color: "#ff6300" }}
        >
          Technical partnership
        </h6>
      </div>
      <div className="position-relative mx-5  " style={{ maxWidth: "100%" }}>
        <img
          className="d-block w-100 rounded-3 shadow-sm"
          src={slides[currentIndex].src}
          alt={slides[currentIndex].alt}
          style={{
            height: "auto",
            maxHeight: "auto ",
            objectFit: "cover",
          }}
        />

        {/* Left Arrow */}
        <button
          className="position-absolute top-50 start-0 translate-middle-y bg-light text-black border-0 rounded-circle p-3 d-none d-md-block"
          onClick={goToPrevious}
          aria-label="Previous slide"
        >
          &lt;
        </button>

        {/* Right Arrow */}
        <button
          className="position-absolute top-50 end-0 translate-middle-y bg-light text-black border-0 rounded-circle p-3 d-none d-md-block"
          onClick={goToNext}
          aria-label="Next slide"
        >
          &gt;
        </button>
      </div>

      {/* Indicators */}
      <div className="text-center mt-3">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`btn btn-sm mx-1 ${
              currentIndex === index ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default CustomCarousel;
