import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import backgroundImg from '../../Images/background.jpg'
const Enchanced = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="py-3">
      <Container>
        <div className="my-3 pt-2">
          <p
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-2 "
            style={{ fontWeight: "bold", color: "#ff6300" }}
          >
            Enhanced Capabilities
          </p>
        </div>
        <Row className="g-6 pb-5  ">
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Mobile Compatibility
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              Responsive design for seamless access on all devices. Dedicated
              mobile apps for learning on-the-go.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Integration with Other Systems
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              Integrate with HRIS, CRM, and other enterprise systems.Ensure
              seamless data exchange and centralized management
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Customization and Branding
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              Customize the LMS interface to align with your branding.Tailor the
              look and feel to meet your organization's needs.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Enchanced;
