import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import LimsHero from "./LimsHero";
import LimsFeatures from "./LimsFeatures";
import BookDemo from "./BookDemo";
import WhyChooseLims from "./WhyChooseLims";
import LimsPoised from "./Poised";
import { useMediaQuery } from "react-responsive";

const LIMS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "26px" : "40px" }}
                          className="text-uppercase"
                        >
                          A Comprehensive Solution Shaping the Future of
                          Laboratory Management
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <LimsHero />
          <LimsFeatures />
          <WhyChooseLims />
          <LimsPoised />
          <BookDemo />
        </div>
      </div>
    </div>
  );
};

export default LIMS;
