import React, { useState } from "react";
import lowcode from "../../../Images/products/LIMS Poster/3.jpg";
import versility from "../../../Images/products/LIMS Poster/1.jpg";
import modernteck from "../../../Images/products/LIMS Poster/2.jpg";

const WhyChooseLims = () => {
  // Set the default selectedId to 1
  const [selectedId, setSelectedId] = useState(1);

  const contentData = [
    {
      id: 1,
      head: "Optimizing Laboratory Operations:",
      content:
        "Streamlined sample data access, workflow management, and real-time tracking for improved efficiency and precision.",
      image: modernteck,
    },

    {
      id: 2,
      head: "Simplified Management:",
      content:
        "Automates sample lifecycle, inventory, compliance, and reporting, freeing managers to focus on strategy.",
      image: lowcode,
    },
    {
      id: 3,
      head: "Accurate Insights: ",
      content:
        "Comprehensive analytics and reporting for informed, data-driven decision-making.",
      image: versility,
    },
  ];

  return (
    <div className="container my-5 ">
      <div className="text-center my-4">
        <p
          className="display-4 mx-4 py-3"
          style={{ color: "#ff6300", fontWeight: "bold" }}
        >
          Why Choose LIMS?
        </p>
      </div>
      <div className="row py-3 ">
        <div className="col-md-6">
          <div className="image-container">
            <img
              src={
                selectedId
                  ? contentData.find((item) => item.id === selectedId).image
                  : ""
              }
              alt="Selected Content"
              className="img-fluid rounded-3"
              style={{
                height: "400px",
                objectFit: "cover",
                width: "100%",
              }}
            />
          </div>
        </div>

        {/* Right Column: Custom Accordion */}
        <div className="col-md-6">
          {contentData.map((item) => (
            <div
              key={item.id}
              style={{
                backgroundColor: "white",
                boxShadow: "none",
                marginBottom: "10px",
              }}
            >
              <div
                className="accordion-header"
                onClick={() =>
                  setSelectedId(selectedId === item.id ? null : item.id)
                }
                style={{
                  color: selectedId === item.id ? "#ff6300" : "#010c54",
                  fontSize: selectedId === item.id ? "2rem" : "1.3rem",
                  fontWeight: selectedId === item.id ? "bold" : "bolder",
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: selectedId === item.id ? "#F4FAFF" : "",
                  borderLeft:
                    selectedId === item.id ? "5px solid #ff6300" : "none",
                }}
              >
                {item.head}
              </div>
              {selectedId === item.id && (
                <div
                  className="accordion-body h5"
                  style={{
                    padding: "10px",
                    color: selectedId === item.id ? "#010c54" : "",
                    fontWeight: selectedId === item.id ? "bold " : "",
                    backgroundColor: "#F4FAFF",
                    borderLeft:
                      selectedId === item.id ? "5px solid #ff6300" : "none",
                  }}
                >
                  {item.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseLims;
