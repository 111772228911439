import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../../src/Images/products/srm/Security.gif";
import Convenient from "../../../../src/Images/products/srm/Analytics.gif";
import Personalized from "../../../../src/Images/products/srm/Integration.gif";

const AdditonalFeature = () => {
  const features = [
    {
      name: "Security",
      img: Assessments,
      content: "Role-based access controls and data encryption.",
      subcontent: "Blockchain integration for tamper-proof records.",
    },
    {
      name: "Reporting & Analytics",
      img: Convenient,
      content: "Dashboards for real-time insights.",
      subcontent: "Customizable reports for compliance and performance",
    },
    {
      name: "Integration",
      img: Personalized,
      content: "ERP and financial systems for unified operation.",
      subcontent: "APIs for seamless data exchange with external systems",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5  pt-4">
      <div className="container text-center my-2">
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            Additional Features Across All Processes
          </p>
        </div>
        <div className="row gy-4">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center bt-3"
            >
              <div
                className="card shadow-md"
                style={{ width: "20rem", borderTop: "8px solid #ff6300 " }}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "70%",
                      height: "auto",
                      padding: "10%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div className="card-body">
                  <p
                    className="card-title  fw-bold pb-2 h4"
                    style={{ textAlign: "center", color: "#ff6300 " }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "justify" }}
                  >
                    {item.content}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "justify" }}
                  >
                    {item.subcontent}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdditonalFeature;
