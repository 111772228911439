import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Partners = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="py-5">
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Why We Are Right Partners?
        </p>
      </div>
      <Container className="pb-5">
        <Row className="gy-4 ">
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Decades of{" "}
                <span style={{ color: "#ff6300" }}>industry experience</span>{" "}
                having domain, Technology and Solutioning expertise
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                <span style={{ color: "#ff6300" }}>Asset management</span>{" "}
                product focused organization
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                <span style={{ color: "#ff6300" }}>
                  {" "}
                  Simple, better and intuitive
                </span>{" "}
                user interface approach for ease of adaptation
              </p>
            </div>
          </Col>
        </Row>
        <Row
          className="gy-4 mt-4 d-flex justify-content-center "
          style={{ color: "#ff6300" }}
        >
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Focused on{" "}
                <span style={{ color: "#ff6300" }}>current technologies</span>{" "}
                (QR Code, Mobile, Data Analytics)
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                <span style={{ color: "#ff6300" }}>Capability of global</span>{" "}
                service provider with flexibility and responsiveness of a
                boutique sized company
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                <span style={{ color: "#ff6300" }}>Ability to scale</span> and
                service changing needs to delivery value
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Partners;
