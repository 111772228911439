import React, { useEffect } from "react";
import styles from "./style.module.css";
import EdmsHeroContent from "./EdmsHeroContent";
import FeaturesOfEdms from "./FeaturesOfEdms";
import WhoweAre from "./WhoweAre";
import Digitec from "./Digitec";
import Client from "./OurClients";
import { EfficientsOfEdms } from "./EfficientsOfEdms";
import { Col, Container, Row } from "react-bootstrap";
import "../../Products/style.css";
import BookDemo from "./BookDemo";
import { useMediaQuery } from "react-responsive";

const Edms = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "28px" : "40px" }}
                          className="text-uppercase"
                        >
                          DIGITEC EDMS: Simplify, Secure, and Streamline Your
                          Document Journey
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <EdmsHeroContent />
      <EfficientsOfEdms />
      <WhoweAre />
      <FeaturesOfEdms />
      <Client />
      <Digitec />
      <BookDemo />
    </div>
  );
};

export default Edms;
