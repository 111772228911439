import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import dummy from "../../../Images/products/eams/eams.jpg";
export const Solution = () => {
  return (
    <div>
      {" "}
      <Container className="py-5">
        <Row className="align-items-start">
          {/* Image Column */}
          <Col lg={6} md={6} sm={12} className="mb-3 d-none d-sm-block">
            <img
              src={dummy}
              alt="Cloudera Data Platform"
              className="img-fluid rounded"
            />
          </Col>
          {/* Text Column */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 justify-content-around px-lg-4"
          >
            <div className="pb-3">
              <div>
                <p
                  className="display-4"
                  style={{ color: "#ff6300", fontWeight: "bold" }}
                >
                  EAMS – Solution’s Value Proposition
                </p>
              </div>
            </div>

            <p
              className="pb-4 h4 fw-bold pt-lg-2"
              style={{ textAlign: "justify", color: "#010c54" }}
            >
              <ol>
                <li>Knowledge base with decades of experience</li>
                <li>Ease of use Bring Simplicity and user experience</li>
                <li>Pre Engineered approach for fast track implementation</li>
                <li>Provide operational efficiency & Excellence</li>
                <li>
                  Web based solution with Single / Multi location configuration
                </li>
                <li>Compliance to ISO standards (55000, 31000, 9001)</li>
              </ol>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
