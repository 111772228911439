import React from "react";

const LimsPoised = () => {
  return (
    <div>
      <div className="container  py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              {" "}
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Why LIMS is Poised for Market Success
              </p>
            </div>

            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Revolutionizing Laboratory Efficiency:
              </span>{" "}
              LIMS addresses the growing demand for efficient and accurate
              laboratory management by automating workflows, optimizing resource
              utilization, and ensuring compliance with regulatory standards.
              Its ability to eliminate manual errors and streamline operations
              makes it indispensable for modern laboratories.
            </p>
            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Empowering Data-Driven Decisions:
              </span>{" "}
              In an era of big data, LIMS excels by providing real-time
              analytics and comprehensive reporting. Laboratories can leverage
              these insights to enhance decision-making, improve turnaround
              times, and innovate with confidence.
            </p>
            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Scalability and Integration:
              </span>{" "}
              LIMS is designed to grow with laboratories, adapting to increasing
              volumes and complex requirements. Its seamless integration with
              instruments, enterprise systems, and third-party tools ensures a
              future-ready platform for evolving needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimsPoised;
