import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Container, Row, Col } from "react-bootstrap";

const categories = [
  "Banking, Financial, Securities & Insurance (BFSI)",
  "Land & Buildings",
  "Classrooms",
  "Furniture's & Fittings",
  "Lab & Workshops",
  "IT & Communications",
  "Electrical Equipments",
  "Mechanical Equipments",
  "Gardens / Outdoor Lighting",
  "Sports / Gym Equipments",
  "Lifts / Fire Fighting Equipments",
  "Canteen / Kitchen Equipments",
  "Water Treatment / Sanitation",
  "Transport Vehicles",
];

const AssetsCoverage = () => {
  return (
    <Container className="py-5">
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          TrackPro’s EAMS – Institution Asset Coverage
        </p>
      </div>
      <Row className="gy-5">
        {categories.map((category, index) => (
          <Col lg={4} md={6} key={index}>
            <Card
              className="h-100 shadow-sm border-0"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <Card.Body className="text-center fw-bold">
                <Card.Title
                  className="h5 fw-bold justify-content-center  align-items-center d-flex"
                  style={{ color: "#010c54" }}
                >
                  {category}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AssetsCoverage;
