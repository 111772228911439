import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../../src/Images/products/lms/course.gif";
import Convenient from "../../../../src/Images/products/lms/user.gif";
import Personalized from "../../../../src/Images/products/lms/content.gif";
import Measurable from "../../../../src/Images/products/lms/assessment.gif";

const HrmsFeatures = () => {
  const features = [
    {
      name: "Real-Time Performance Reviews and Feedback",
      img: Measurable,
      content:
        "Continuous performance tracking and timely feedback help managers make data-driven adjustments to development plans, boosting productivity and driving consistent performance improvement.",
    },

    {
      name: "Recruitment and Onboarding",
      img: Convenient,
      content:
        " The platform accelerates recruitment by automating candidate matching and workflows, streamlining the hiring process from job posting to seamless onboarding of new hires.",
    },
    {
      name: "Leave and Attendance Tracking",
      img: Personalized,
      content:
        "Real-time tracking of employee attendance, leave requests, and work schedules allows managers to optimize resource planning, ensuring compliance with company policies.",
    },
    {
      name: "Personalized Employee Management and Career Planning",
      img: Assessments,
      content:
        "HRMS creates customized career development plans by leveraging comprehensive employee profiles and performance data, ensuring relevant growth opportunities that enhance engagement and retention.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5 pt-4">
      <div className="container text-center my-2">
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Features of HRMS
        </p>
        <div className="row gy-4 d-flex justify-content-center">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center"
            >
              <div
                className="card shadow-md d-flex flex-column align-items-center"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  borderTop: "8px solid #ff6300",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "70%",
                      height: "auto",
                      padding: "10%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div
                  className="card-body d-flex flex-column align-items-stretch"
                  style={{ flex: "1 1 auto", textAlign: "center" }}
                >
                  <p
                    className="card-title fw-bold h5"
                    style={{ color: "#ff6300", minHeight: "3rem" }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="card-text flex-grow-1 h5"
                    style={{
                      textAlign: "left",
                      minHeight: "7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#010c54",
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HrmsFeatures;
