import React from "react";

const WhyChoose = () => {
  return (
    <div>
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              {" "}
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Why Choose ISMS?
              </p>
            </div>

            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Empowering Parents:
              </span>
              ISMS equips parents with insights into their child’s progress and
              provides personalized plans that adapt over time. Parents gain
              peace of mind knowing that they are part of a structured support
              system.
            </p>
            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Supporting Schools:
              </span>{" "}
              Schools benefit from streamlined access to assessment data and
              intervention plans, making it easier to accommodate neurodivergent
              students in the classroom and promote an inclusive learning
              environment.
            </p>
            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Enhancing Therapy Outcomes:
              </span>{" "}
              For therapists, ISMS offers an integrated tool for tracking each
              child’s development in real time, allowing for continuous
              adjustments and more effective interventions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
