import styles from "./styles.module.css";
import "../../App.css";

import TTIPLLOGO2 from "../../Images/products/Talent-Takeaways-Logomark.png";

import { Nav, Navbar } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function CommonNav(props) {
  const handleClick = () => {
    navigate("/zoho-implementation");
  };

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { navbar, navbarscrollMain } = props;

  const { pathname } = useLocation();

  const NavbarClickbutton = () => {
    var element = document.getElementById("navbarScroll");
    element.classList.add("show");
    element.classList
      .remove("show")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <Navbar
        ref={navbarscrollMain}
        expand="md"
        className={
          navbar === true
            ? styles.MainHeaderNavbar
            : isMobile
            ? styles.MainHeaderNavbar
            : ""
        }
      >
        {isMobile || navbar === true ? (
          <div className={styles.container}>
            <img
              onClick={() => handleClick()}
              src={TTIPLLOGO2}
              style={{ cursor: "pointer" }}
              id="Mainhead"
              className={styles.image1}
              alt="logo"
            />
          </div>
        ) : (
          ""
        )}

        <Navbar.Toggle
          style={{ border: "0px" }}
          aria-controls="navbarScroll"
          id={styles.icons}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0 m-auto"
            style={{
              maxHeight: "500px",
              width: "60%",
              justifyContent: "space-between",
            }}
            navbarScroll
          >
            <Link
              to="/zoho-implementation"
              className={
                pathname.split("/")[1] === "zoho-marketing" && styles.active
              }
              id={styles.navhead}
            >
              HOME
            </Link>

            <Link
              to="/zoho-marketing#whyus"
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              WHY US
            </Link>

            <Link
              to="/zoho-marketing#ourfeatures"
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              OUR FEATURES
            </Link>
            <Link
              to="/zoho-marketing#oursolutions"
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              OUR SOLUTIONS
            </Link>
            <Link
              to="/zoho-marketing#ourclients"
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              OUR CLIENTS
            </Link>
            <Link
              to="/zoho-marketing#footer"
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              CONTACT
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
