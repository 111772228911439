import React, { useState } from "react";
import { Carousel, Card, Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive"; // Import useMediaQuery
import Knomadix from "../../Images/products/clientLogo/Knomadix.png";
import ura from "../../Images/products/clientLogo/URA.png";
import TNASDC from "../../Images/products/clientLogo/tnsad.png";
import elina from "../../Images/products/clientLogo/elina.png";
import nanmudhalvan from "../../Images/products/clientLogo/nanmudhalvan.png";
import mulhud from "../../Images/products/clientLogo/uganda.png";
import wakiso from "../../Images/products/clientLogo/wakiso.jpg";
import fia from "../../Images/products/clientLogo/fia.jpg";

const Customers = () => {
  const cardData = [
    {
      id: 1,
      title: "Knomadix AI",
      text: "Knomadix are dedicated to transforming education through inclusive and collaborative learning experiences",
      imgSrc: Knomadix,
    },
    {
      id: 2,
      title: "Uganda Revenue Authority",
      text: "To assess, collect, and manage Central Government tax and non-tax revenues, and provide policy advice on all revenue sources.",
      imgSrc: ura,
    },
    {
      id: 3,
      title: "Tamil Nadu Apex Skill Development Centre for Logistics",
      text: "TNASDL specializes in delivering comprehensive Supply Chain and Logistics skills through advanced, cutting-edge technologies.",
      imgSrc: TNASDC,
    },
    {
      id: 4,
      title: "Elina Services",
      text: "Elina envision a world where every child is provided with the tools and support to realize their full potential.",
      imgSrc: elina,
    },
    {
      id: 5,
      title: "Nan Mudhalvan",
      text: "Naan Mudhalvan platform aims to provide dynamic information for college students on courses and relevant information about industry specific skill offerings.",
      imgSrc: nanmudhalvan,
    },
    {
      id: 6,
      title: "Ministry of Lands, Housing & Urban Development",
      text: "MLHUD are responsible for providing policy direction, national standards and coordination of all matters concerning lands, housing and urban development.",
      imgSrc: mulhud,
    },
    {
      id: 7,
      title: "Wakiso District Local Government",
      text: "Formerly part of Mpigi District, it came into existence in 2000, when the 3 counties of Mpigi District-Busiro, Kyadondo and Entebbe Municipality became Wakiso District.",
      imgSrc: wakiso,
    },
    {
      id: 8,
      title: "The Financial Intelligence Authority",
      text: "The Financial Intelligence Authority(FIA) is Uganda's National centre for the receipt of financial data, analysis and dissemination of financial intelligence to competent authorities.",
      imgSrc: fia,
    },
  ];

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });
  const cardsPerSlide = isMobile ? 1 : 3;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + cardsPerSlide < cardData.length
        ? prevIndex + cardsPerSlide
        : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - cardsPerSlide >= 0
        ? prevIndex - cardsPerSlide
        : cardData.length - (cardData.length % cardsPerSlide || cardsPerSlide)
    );
  };

  return (
    <>
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
        >
          Our Customers
        </p>
      </div>
      <Container
        className="mb-4 mt-2"
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <button
            onClick={handlePrev}
            style={{
              background: "none",
              border: "none",
              fontSize: "2rem",
              cursor: "pointer",
            }}
          >
            &#8249;
          </button>
          <div className="d-flex flex-wrap justify-content-center gap-3">
            {cardData
              .slice(currentIndex, currentIndex + cardsPerSlide)
              .map((card) => (
                <Card
                  style={{
                    width: isMobile ? "100%" : "20rem", // Adjust card width for mobile
                    margin: "10px",
                    borderTop: "8px solid #ff6300",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    backgroundColor: "white",
                  }}
                  key={card.id}
                  className="shadow"
                >
                  <Card.Img
                    variant="top"
                    src={card.imgSrc}
                    className="p-2"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "contain", // Ensures the image fits within the div
                      margin: "auto",
                      // Optional: Adds a background for empty space
                    }}
                  />
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ff6300" }}
                      className="fw-bold d-flex h4"
                    >
                      {card.title}
                    </Card.Title>
                    <Card.Text
                      className="h5 fw-bold"
                      style={{ textAlign: "left", color: "#010c54" }}
                    >
                      {card.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
          </div>
          <button
            onClick={handleNext}
            style={{
              background: "none",
              border: "none",
              fontSize: "2rem",
              cursor: "pointer",
            }}
          >
            &#8250;
          </button>
        </div>
      </Container>
    </>
  );
};

export default Customers;
