import React, { useEffect, useState, useRef } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "./WhyChooseRdmp.css"; // Custom CSS for animations
import Development from "../../../Images/products/features/developemnet.gif";
import developer from "../../../Images/products/features/developer.gif";
import fasttime from "../../../Images/products/features/fasttime.gif";
const WhyChoosRdmp = () => {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const cardData = [
    {
      id: 1,
      icon: developer,
      title: "For Developers of All Skill Levels",
      text: "Empower even those with minimal technical expertise.",
    },
    {
      id: 2,
      icon: Development,
      title: "Streamlined Development Process",
      text: "Agile and adaptive to various business needs.",
    },
    {
      id: 3,
      icon: fasttime,
      title: "Faster Time-to-Market",
      text: "Deliver quality applications rapidly without compromising performance.",
    },
  ];

  const sectionRef = useRef(null); // Reference to the section element

  // Intersection Observer to detect visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when the section comes into view
        }
      },
      { threshold: 0.5 } // 50% of the section needs to be in the viewport
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Observe the section
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); // Cleanup the observer when component unmounts
      }
    };
  }, []);

  return (
    <>
      {/* style={{ backgroundColor: "#F4FAFF" }} */}
      <div className="py-5" style={{ backgroundColor: "#F4FAFF" }}>
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className={`display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 ${
              isVisible ? "slide-down" : ""
            }`}
          >
            Why Choose RDMP?
          </p>
        </div>
        <Container className="mb-5" ref={sectionRef}>
          <Row className="g-4 d-flex justify-content-center">
            {cardData.map((card, index) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={card.id}
                className={`${isVisible ? "fade-in" : ""}`} // Trigger fade-in when visible
                style={{ animationDelay: `${index * 0.2}s` }} // Delay for each card
              >
                <Card
                  className="shadow-sm py-3"
                  style={{
                    maxWidth: "18rem",
                    height: "100%", // Ensure consistent height
                    margin: "0 auto", // Center the cards horizontally
                    borderTop: "8px solid #ff6300",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Img
                      src={card.icon}
                      alt={card.title}
                      style={{
                        maxWidth: "50%",
                        height: "auto",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                    <Card.Title
                      className="text-center mb-3 h4"
                      style={{ fontWeight: "bold", color: "#ff6300" }}
                    >
                      {card.title}
                    </Card.Title>
                    <Card.Text
                      className="text-center h5"
                      style={{ color: "#010c54" }}
                    >
                      {card.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhyChoosRdmp;
