import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive"; // Import useMediaQuery
import resource from "../../../../src/Images/products/lms/resource.gif";
import flexible from "../../../../src/Images/products/lms/flexible.gif";
import performance from "../../../../src/Images/products/lms/performance.gif";
import centerlaized from "../../../../src/Images/products/lms/centerlaized.gif";
import communication from "../../../../src/Images/products/lms/communication.gif";

const Benefits = () => {
  const cardData = [
    {
      id: 1,
      title: "Resource Management",
      text: "Efficiently manage educational resources, ensuring equal access to learning materials. ",
      imgSrc: resource,
    },
    {
      id: 2,
      title: "Flexible Learning Options",
      text: "Support for blended learning, flipped classrooms, and online courses, offering diverse ways to deliver education.",
      imgSrc: flexible,
    },
    {
      id: 3,
      title: "Performance Tracking",
      text: "Monitor learner progress and achievements, making it easier to adapt learning paths based on individual needs.",
      imgSrc: performance,
    },
    {
      id: 4,
      title: "Centralized Learning Platform",
      text: "Manage course materials, assignments, and grades all in one place, streamlining the educational process.",
      imgSrc: centerlaized,
    },
    {
      id: 5,
      title: "Enhanced Communication",
      text: "Facilitate better communication between learners, trainers, and other stakeholders, and foster collaboration through discussion forums and group projects.",
      imgSrc: communication,
    },
  ];

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile screens
  const cardsPerSlide = isMobile ? 1 : 3; // Dynamically set cards per slide based on screen size

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + cardsPerSlide < cardData.length
        ? prevIndex + cardsPerSlide
        : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - cardsPerSlide >= 0
        ? prevIndex - cardsPerSlide
        : cardData.length - (cardData.length % cardsPerSlide || cardsPerSlide)
    );
  };

  return (
    <>
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
        >
          Benefits for Institutions
        </p>
      </div>
      <Container
        className="mb-4 mt-2"
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <button
            onClick={handlePrev}
            style={{
              background: "none",
              border: "none",
              fontSize: "2rem",
              cursor: "pointer",
            }}
          >
            &#8249;
          </button>
          <div className="d-flex flex-wrap justify-content-center gap-3">
            {cardData
              .slice(currentIndex, currentIndex + cardsPerSlide)
              .map((card) => (
                <Card
                  style={{
                    width: isMobile ? "100%" : "20rem", // Adjust card width for mobile
                    margin: "10px",
                    borderTop: "8px solid #ff6300",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    backgroundColor: "white",
                  }}
                  key={card.id}
                  className="shadow"
                >
                  <Card.Img
                    variant="top"
                    src={card.imgSrc}
                    className="p-2"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      margin: "auto",
                    }}
                  />
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ff6300 " }}
                      className="fw-bolder justify-content-center d-flex"
                    >
                      {card.title}
                    </Card.Title>
                    <Card.Text
                      className="h5 fw-bold"
                      style={{ color: "#010c54" }}
                    >
                      {card.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
          </div>
          <button
            onClick={handleNext}
            style={{
              background: "none",
              border: "none",
              fontSize: "2rem",
              cursor: "pointer",
            }}
          >
            &#8250;
          </button>
        </div>
      </Container>
    </>
  );
};

export default Benefits;
