import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../../src/Images/products/lms/course.gif";
import Convenient from "../../../../src/Images/products/lms/user.gif";
import Personalized from "../../../../src/Images/products/lms/content.gif";
import Measurable from "../../../../src/Images/products/lms/assessment.gif";
import scan from "../../../../src/Images/products/lms/scan.gif";
const LimsFeatures = () => {
  const features = [
    {
      name: "Personalized Sample Management and Workflow Automation",
      img: Assessments,
      content:
        "LIMS streamlines operations with advanced sample tracking and customizable workflows, ensuring precise and efficient handling from registration to disposal.",
    },
    {
      name: "Instrument Integration and Data Acquisition",
      img: Convenient,
      content:
        " Seamlessly integrating with lab instruments, LIMS automates data acquisition, reducing manual input, improving accuracy, and accelerating test results.",
    },
    {
      name: "Inventory and Resource Management",
      img: Personalized,
      content:
        "LIMS manages inventory and resources with real-time updates and alerts for low stock or maintenance, ensuring uninterrupted and efficient lab operations.",
    },
    {
      name: "Compliance and Audit Trail Management",
      img: scan,
      content:
        "LIMS maintains a secure, detailed audit trail of all laboratory activities. This feature simplifies regulatory compliance ensuring data integrity and accountability.",
    },
    {
      name: "Real-Time Reporting and Analytics",
      img: Measurable,
      content:
        "LIMS offers customizable dashboards and real-time analytics, enabling data-driven decisions to optimize workflows and boost productivity.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5 pt-4">
      <div className="container text-center my-2">
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Features of LIMS
        </p>
        <div className="row gy-4 d-flex justify-content-center">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center"
            >
              <div
                className="card shadow-md d-flex flex-column align-items-center"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  borderTop: "8px solid #ff6300",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "70%",
                      height: "auto",
                      padding: "10%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div
                  className="card-body d-flex flex-column align-items-stretch"
                  style={{ flex: "1 1 auto", textAlign: "center" }}
                >
                  <p
                    className="card-title fw-bold h5"
                    style={{ color: "#ff6300", minHeight: "3rem" }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="card-text flex-grow-1 h5"
                    style={{
                      textAlign: "justify",
                      minHeight: "7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#010c54",
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LimsFeatures;
