import React, { useState } from "react";
import DemoModel from "../DemoModel/DemoModel";

const BookDemo = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center py-5  "
      style={{ backgroundColor: "#F4FAFF" }}
    >
      <p
        className="display-4 mb-4 pt-5"
        style={{ fontWeight: "bold", color: "#ff6300" }}
      >
        Ready to explore more?
      </p>
      <button
        className="btn btn-primary btn-lg mb-5"
        style={{
          backgroundColor: "#010c54",
          borderColor: "#010c54",
          fontWeight: "bold",
        }}
        onClick={handleOpenModal}
      >
        Book a Demo
      </button>
      <DemoModel
        show={showModal}
        handleClose={handleCloseModal}
        dropDrownValue={["SRM"]}
      />
    </div>
  );
};

export default BookDemo;
