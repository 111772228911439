import React from "react";

import styles from "./styles.module.css";
import "../../../src/App.css";

import TTIPLLOGO2 from "../../Images/products/talent_takeaways.png";

import { Nav, Navbar } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const ZohoNavbarLanding = (props) => {
  const handleClick = () => {
    navigate("/zoho-implementation");
  };

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    navbar,

    navbarscrollMain,
  } = props;

  const { pathname } = useLocation();

  const NavbarClickbutton = () => {
    var element = document.getElementById("navbarScroll");
    element.classList.add("show");
    element.classList
      .remove("show")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <Navbar
        ref={navbarscrollMain}
        expand="md"
        className={
          navbar === true
            ? styles.MainHeaderNavbar
            : isMobile
            ? styles.MainHeaderNavbar
            : ""
        }
      >
        {isMobile || navbar === true ? (
          <div className={styles.container}>
            <img
              onClick={() => handleClick()}
              src={TTIPLLOGO2}
              style={{ cursor: "pointer" }}
              id="Mainhead"
              className={styles.image1}
              alt="logo"
            />
          </div>
        ) : (
          ""
        )}

        <Navbar.Toggle
          style={{ border: "0px" }}
          aria-controls="navbarScroll"
          id={styles.icons}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0 m-auto"
            style={{
              maxHeight: "500px",
              width: "60%",
              justifyContent: "center",
            }}
            navbarScroll
          >
            <Link
              to="/"
              className={
                pathname.split("/")[1] === "zoho-implementation" &&
                `${styles.active} mx-3`
              }
              id={styles.navhead}
            >
              HOME
            </Link>

            <Link
              to="/zoho-marketing"
              target="_blank"
              onClick={NavbarClickbutton}
              className="mx-3"
              id={styles.navhead}
            >
              UNIFIED MARKETING PLATFORM
            </Link>

            <Link
              to="/zoho-implementation#whatwedo"
              onClick={NavbarClickbutton}
              className="mx-3"
              id={styles.navhead}
            >
              WHAT WE DO
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default ZohoNavbarLanding;
