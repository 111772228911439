import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const EffortsOfIsms = () => {
  return (
    <div className="container text-center py-5">
      <div className="row justify-content-center">
        <div className="col-md-8 mb-4">
          <div>
            {" "}
            <p
              style={{ color: "#ff6300", fontWeight: "bold" }}
              className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
            >
              Can you effortlessly observe your child's daily progress?
            </p>
          </div>

          <p className="fw-bold h4" style={{ color: "#010c54" }}>
            What alternative approaches can be considered if traditional early
            intervention services are not readily available or accessible for
            supporting a child's development?
          </p>
        </div>
      </div>
    </div>
  );
};

export default EffortsOfIsms;
