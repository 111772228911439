import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import "../../Products/style.module.css";
import { Reason } from "./Reason";
import { Solution } from "./Solution";
import { Highlights } from "./HIghlights";
import { Flowchart } from "./Flowchart";
import Partners from "./Partners";
import AssetsCoverage from "./Assets";
import BookDemo from "./BookDemo";
import { useMediaQuery } from "react-responsive";
import { EamsHero } from "./EamsHero";

const Eams = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "25px" : "40px" }}
                          className="text-uppercase"
                        >
                          Simplifying Asset Management for Energy,
                          Infrastructure, Manufacturing, and
                          Healthcare—Effortless, Efficient, Enjoyable.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <EamsHero />
        <Reason />
        <Highlights />
        <Solution />
        <Partners />
        <AssetsCoverage />
        <BookDemo />
        {/* <Flowchart /> */}
      </div>
    </div>
  );
};

export default Eams;
