import React from "react";

const HrmsPoised = () => {
  return (
    <div>
      <div className="container  py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              {" "}
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Why HRMS is Poised for Market Success
              </p>
            </div>

            <p className="h5 fw-bold pb-3" style={{ textAlign: "justify" }}>
              <span style={{ color: "#010c54" }} className="fw-bold">
                Growing Demand for Workforce Management Solutions:
              </span>{" "}
              As businesses grow and evolve, the need for efficient human
              resource management has never been more critical. With the
              increasing complexity of managing diverse workforces, the demand
              for digital HR systems continues to rise. The global HR technology
              market is expanding rapidly, with a projected growth rate of
              10-15% annually. HRMS is well-positioned to capitalize on this
              growth, offering a comprehensive, scalable solution for modern HR
              management.
            </p>
            <p className="h5 fw-bold pb-3" style={{ textAlign: "justify" }}>
              <span style={{ color: "#010c54" }} className="fw-bold">
                High-Impact Solution for Organizations and Employees:
              </span>{" "}
              HRMS bridges the gap between employees and HR teams by providing a
              unified platform that simplifies workforce management. HR
              departments face ongoing challenges with recruitment, performance
              management, and employee engagement, while employees seek more
              transparency and streamlined processes. HRMS addresses these needs
              by offering a user-friendly, all-in-one solution that empowers HR
              teams, supports employees, and drives operational efficiency
              across organizations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrmsPoised;
