import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dummy from "../../../Images/products/Human Resource Management system/hmss.png";
// import bg from "../../../Images/products/rdmp/bg.png";
import demos from "../../../Images/products/Human Resource Management system/hrms.mp4";
import { FaPlay } from "react-icons/fa";
const HrmsHeroContent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  return (
    <div>
      <Container className="py-5">
        <Row className="align-items-start flex-column-reverse flex-sm-row">
          {/* Text Column */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 justify-content-around px-lg-4"
          >
            <div className="pb-3">
              <div>
                <p
                  className="display-4"
                  style={{ color: "#ff6300", fontWeight: "bold" }}
                >
                  Human Resource Management System
                </p>
              </div>
            </div>

            <p
              className="pb-4 h4 fw-bold pt-lg-2"
              style={{ textAlign: "justify", color: "#010c54" }}
            >
              Human Resource Management System (HRMS) by TTIPL is a modern
              solution designed to streamline workforce management and enhance
              employee engagement. Tailored for HR professionals, managers, and
              employees, it simplifies employee lifecycle management,
              recruitment, leave tracking, and performance monitoring to meet
              the dynamic needs of today's businesses.
            </p>
          </Col>

          {/* Image Column */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 d-flex justify-content-center align-items-center position-relative"
          >
            {!isPlaying ? (
              <div
                onClick={handlePlayVideo}
                style={{
                  height: "400px",
                  width: "100%",
                  backgroundImage: `url(${dummy})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaPlay />
                </div>
              </div>
            ) : (
              <video
                controls
                className="img-fluid rounded"
                style={{
                  height: "400px",
                  width: "100%",
                }}
                alt="Cloudera Data Platform"
              >
                <source src={demos} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HrmsHeroContent;
