import React, { useEffect } from "react";
import styles from "./style.module.css";

import LmsHeroContent from "./LmsHeroContent";
import FeaturesOfLMS from "./FeaturesOfLMS";
import AdvantageOfLms from "./AdvantageofLms";
import EducationNeed from "./EducationalNeeds";
import Enchanced from "./Enchanced";
import Benefits from "./Benefits";
import Empowering from "./Empowering";
import { Col, Container, Row } from "react-bootstrap";
import "../../Products/style.css";
import BookDemo from "./BookDemo";
import { useMediaQuery } from "react-responsive";

const Lms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "28px" : "40px" }}
                          className="text-uppercase"
                        >
                          Revolutionize Your Training with Talent Takeaways
                          E-LMS
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <LmsHeroContent />
      <FeaturesOfLMS />
      <AdvantageOfLms />
      <Enchanced />
      <Benefits />
      <Empowering />
      <EducationNeed />
      <BookDemo />
    </div>
  );
};

export default Lms;
