import React from "react";

export const Reason = () => {
  return (
    <div>
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Reasons to Invest in TrackPro’s EAMS
              </p>
            </div>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              Vision to provide niche solution and services to the customers to
              derive more value
            </p>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              Strong mix of Domain, Technology and Management expertise
            </p>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              Conceptualizing and delivering business solutions across the value
              chain
            </p>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              Innovative and efficient methodologies to work smarter and lean
              approach
            </p>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              To build partnerships based on Relationship
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// import React, { useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import dummy from "../../../Images/products/eams/eams.mp4";
// import demo from "../../../Images/products/eams/eamss.png";
// import { FaPlay } from "react-icons/fa";
// export const Reason = () => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const handlePlayVideo = () => {
//     setIsPlaying(true);
//   };
//   return (
//     <div>
//       {" "}
//       <Container className="py-5">
//         <Row className="align-items-start flex-column-reverse flex-sm-row">
//           {/* Text Column */}
//           <Col
//             lg={12}
//             md={12}
//             sm={12}
//             className="mb-3 justify-content-around px-lg-4"
//           >
//             <div className="">
//               <div>
//                 <p
//                   className="display-4"
//                   style={{ color: "#ff6300", fontWeight: "bold" }}
//                 >
//                   Reasons to Invest in TrackPro’s EAMS
//                 </p>
//               </div>
//             </div>

//             <p
//               className="pb-4 h4 fw-bold pt-lg-2"
//               style={{ textAlign: "justify", color: "#010c54" }}
//             >
//               <ol style={{ listStyleType: "" }}>
//                 <li>
//                   Vision to provide niche solution and services to the customers
//                   to derive more value
//                 </li>
//                 <li>
//                   Strong mix of Domain, Technology and Management expertise
//                 </li>
//                 <li>
//                   Conceptualizing and delivering business solutions across the
//                   value chain
//                 </li>
//                 <li>
//                   Innovative and efficient methodologies to work smarter and
//                   lean approach
//                 </li>
//                 <li>To build partnerships based on Relationship</li>
//               </ol>
//             </p>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };
