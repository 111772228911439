import React from "react";

export const Highlights = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5">
      <div className="container pt-3">
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          TrackPro’s EAMS – Solution Highlights
        </p>
      </div>
      <div className="container pb-3 px-sm-2">
        <div className="row g-4 justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                1
              </span>
              SECURE
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                2
              </span>
              ROLE BASED ACCESS
            </p>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                3
              </span>
              MODULAR INSIGHTS
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                4
              </span>
              DASHBOARD
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                5
              </span>
              ALERT NOTIFICATION
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                6
              </span>
              FAST TRACK DEPLOYMENT
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                7
              </span>
              LOWER TCO QUICK ROI
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                8
              </span>
              EASE OF USE INTUITIUVE
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                9
              </span>
              OPEN PLATFORM
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
