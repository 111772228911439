import React from "react";

const WhoweAre = () => {
  return (
    <div>
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Who We Are!
              </p>
            </div>
            <p className="h4 fw-bold pb-3 " style={{ color: "#010c54" }}>
              At DIGITEC, our team is composed of passionate individuals united
              by a common goal of fostering business success. We excel in
              implementing Electronic Document Management System (EDMS)
              strategies aimed at elevating your company's online visibility,
              enhancing digital presence, and captivating your target audience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoweAre;
