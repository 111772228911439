import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import backgroundImg from '../../Images/background.jpg'
const Overview = () => {
  return (
    <div className="py-5">
      <Container>
        <div className="mb-3 ">
          <p
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-2 "
            style={{ fontWeight: "bold", color: "#ff6300" }}
          >
            E-Procurement Overview
          </p>
        </div>
        <Row className="g-6 pb-5  ">
          <Col xs={12} md={6} lg={6} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Procurement & Budget Control
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "left" }}
            >
              Centralized platform for procurement plans, real-time budget
              tracking, and cost-saving analytics
            </p>
          </Col>
          <Col xs={12} md={6} lg={6} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Vendor Management & Performance
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "left" }}
            >
              Automated vendor registration, performance metrics, and a
              communication hub
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Streamlined Tendering & Bidding
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "left" }}
            >
              Automated tender creation, multi-format bid submission,
              AI-assisted evaluation, and transparent outcomes.
            </p>
          </Col>
          <Col xs={12} md={6} lg={6} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Amendments and Dispute Resolution
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "left" }}
            >
              A controlled workflow ensures that any amendments or revisions to
              contracts are managed efficiently.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Overview;
