import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Empowering = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }}>
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Empowering Learners
        </p>
      </div>
      <Container className="pb-5">
        <Row className="gy-4 ">
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="fw-bold h4 d-flex justify-content-center "
                style={{ color: "#ff6300" }}
              >
                Access Anytime, Anywhere
              </p>
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Learn from any device with an internet connection, offering
                flexibility and convenience.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="fw-bold h4 d-flex justify-content-center "
                style={{ color: "#ff6300" }}
              >
                Self-Paced Learning
              </p>
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Progress at your own pace, with the ability to revisit complex
                concepts as needed.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="fw-bold h4 d-flex justify-content-center "
                style={{ color: "#ff6300" }}
              >
                Interactive Learning
              </p>
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Engage with multimedia, quizzes, and interactive modules for an
                enriching learning experience.
              </p>
            </div>
          </Col>
        </Row>
        <Row
          className="gy-4 mt-4 d-flex justify-content-center "
          style={{ color: "#ff6300" }}
        >
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="fw-bold h4 d-flex justify-content-center "
                style={{ color: "#ff6300" }}
              >
                Immediate Feedback
              </p>
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Receive real-time feedback to understand performance and areas
                for improvement.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white">
              <p
                className="fw-bold h4 d-flex justify-content-center "
                style={{ color: "#ff6300" }}
              >
                Collaboration Opportunities
              </p>
              <p
                className="h5 fw-bold px-2 py-3"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Develop teamwork skills through group assignments, discussion
                forums, and peer reviews.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Empowering;
