import React from "react";

const EducationNeed = () => {
  return (
    <div>
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              {" "}
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Scalable LMS Solutions for Every Educational Need
              </p>
            </div>

            <p className="h4 fw-bold pb-3" style={{ color: "#010c54" }}>
              Whether you're managing a small training program or a large
              educational institution, our LMS scales seamlessly to meet your
              growing needs. Expand your course offerings without compromising
              on performance or user experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationNeed;
