import React, { useState } from "react";
import dummy from "../../Images/products/rdmp/rdmpp.mp4";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import demo from "../../Images/products/rdmp/rdmpimage.png";
import { FaPlay } from "react-icons/fa";
const HeroContent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="d-flex justify-content-center h5 fw-bold py-5 mb-5"
        style={{ backgroundColor: "#F4FAFF", color: "#010c54" }}
      >
        <p className="fw-bold h5 px-3" style={{ color: "#010c54" }}>
          <span style={{ color: "#ff6300" }} className="fw-bold">
            ISMS :{" "}
          </span>
          Bridging the gap to unlock every child's potential through
          personalized intervention and collaborative support
          <a
            style={{
              // backgroundColor: "#ff6300",
              color: "#ff6300",
              textDecoration: "underline",
              padding: "0.5rem",
              borderRadius: "1rem",
              fontWeight: "bold",
            }}
            href="/isms-products"
          >
            Learn More
          </a>
        </p>
      </div>
      <Container className="pt-5">
        <Row className="align-items-center flex-column-reverse flex-sm-row ">
          <Col
            lg={8}
            md={6}
            sm={12}
            className="mb-3 justify-content-start px-lg-4"
          >
            <div className="pb-3">
              <div>
                <p
                  className=" display-4 "
                  style={{ color: "#ff6300", fontWeight: "bold" }}
                >
                  Rapid Development Methodology Platform
                </p>
              </div>
            </div>

            <p
              className="pb-4 h4 fw-bold"
              style={{ textAlign: "justify", color: "#010c54" }}
            >
              RDMP is a revolutionary platform designed to transform application
              development. Built on ReactJS and NodeJS, it leverages a modern
              tech stack to streamline the development process across diverse
              industries, reducing time and costs while maintaining high-quality
              standards.
            </p>
            <button
              className=" btn-lg px-3 py-2"
              style={{
                backgroundColor: "#010c54",
                borderColor: "#010c54",
                fontWeight: "bold",
                borderRadius: "20px",
                color: "white",
              }}
              onClick={() => navigate("/RDMP")}
            >
              Learn More
            </button>
          </Col>

          <Col
            lg={4}
            md={6}
            sm={12}
            className="mb-3 d-flex justify-content-center align-items-center position-relative"
          >
            {!isPlaying ? (
              <div
                onClick={handlePlayVideo}
                style={{
                  height: "400px",
                  width: "100%",
                  backgroundImage: `url(${demo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaPlay />
                </div>
              </div>
            ) : (
              <video
                controls
                className="img-fluid rounded"
                style={{
                  height: "400px",
                  width: "100%",
                }}
                alt="Cloudera Data Platform"
              >
                <source src={dummy} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default HeroContent;
