import React, { useState } from "react";
import rapid from "../../../../src/Images/products/lms/traning.jpg";
import lowcode from "../../../../src/Images/products/lms/cost.jpg";
import versility from "../../../../src/Images/products/lms/complaint.jpg";
import modernteck from "../../../../src/Images/products/lms/scalable.jpg";
import effectiveness from "../../../../src/Images/products/lms/path.jpg";

const AdvantagesHrms = () => {
  // Set the default selectedId to 1
  const [selectedId, setSelectedId] = useState(1);

  const contentData = [
    {
      id: 1,
      head: "Employee Growth and Satisfaction",
      content:
        "By providing employees with transparent access to their information, career growth paths, and support systems, HRMS ensures that employees remain motivated and engaged.",
      image: modernteck,
    },

    {
      id: 2,
      head: "Improved HR Efficiency",
      content:
        "By automating routine tasks and centralizing data, HRMS frees up HR personnel to focus on strategic initiatives that improve workforce productivity.",
      image: lowcode,
    },
    {
      id: 3,
      head: "Data-Driven Decisions",
      content:
        "The analytics and reporting features of HRMS enable management to make better, data-backed decisions that drive organizational growth.",
      image: versility,
    },
  ];

  return (
    <div className="container my-5 ">
      <div className="text-center my-4">
        <p
          className="display-4 mx-4 py-3"
          style={{ color: "#ff6300", fontWeight: "bold" }}
        >
          Benefits for the Organization
        </p>
      </div>
      <div className="row py-3 ">
        <div className="col-md-6">
          <div className="image-container">
            <img
              src={
                selectedId
                  ? contentData.find((item) => item.id === selectedId).image
                  : ""
              }
              alt="Selected Content"
              className="img-fluid rounded-3"
              style={{
                height: "400px",
                objectFit: "cover",
                width: "100%",
              }}
            />
          </div>
        </div>

        {/* Right Column: Custom Accordion */}
        <div className="col-md-6">
          {contentData.map((item) => (
            <div
              key={item.id}
              style={{
                backgroundColor: "white",
                boxShadow: "none",
                marginBottom: "10px",
              }}
            >
              <div
                className="accordion-header"
                onClick={() =>
                  setSelectedId(selectedId === item.id ? null : item.id)
                }
                style={{
                  color: selectedId === item.id ? "#ff6300" : "#010c54",
                  fontSize: selectedId === item.id ? "2rem" : "1.3rem",
                  fontWeight: selectedId === item.id ? "bold" : "bold",
                  cursor: "pointer",
                  padding: "20px",
                  backgroundColor: selectedId === item.id ? "#F4FAFF" : "",
                  borderLeft:
                    selectedId === item.id ? "5px solid #ff6300" : "none",
                }}
              >
                {item.head}
              </div>
              {selectedId === item.id && (
                <div
                  className="accordion-body h5 fw-bold"
                  style={{
                    padding: "10px 20px",
                    color: selectedId === item.id ? "#010c54" : "",
                    fontWeight: selectedId === item.id ? "normal   " : "bold",
                    backgroundColor: "#F4FAFF",
                    borderLeft:
                      selectedId === item.id ? "5px solid #ff6300" : "none",
                  }}
                >
                  {item.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvantagesHrms;
