import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dummy from "../../../Images/products/rdmp/rdmpp.mp4";

import DemoModel from "../DemoModel/DemoModel";
import demo from "../../../Images/products/rdmp/rdmpimage.png";
import { FaPlay } from "react-icons/fa";

const RdmpHeroContent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div>
      <Container className="py-5">
        <Row className="align-items-start flex-column-reverse flex-sm-row">
          {/* Text Column */}
          <Col
            lg={8}
            md={6}
            sm={12}
            className="mb-3 justify-content-around px-lg-4"
          >
            <div className="pb-3">
              <div>
                <p
                  className="display-4"
                  style={{ color: "#ff6300", fontWeight: "bold" }}
                >
                  Rapid Development Methodology Platform
                </p>
              </div>
            </div>

            <p
              className="pb-4 h4 fw-bold pt-lg-2"
              style={{ textAlign: "justify", color: "#010c54" }}
            >
              RDMP is a revolutionary platform designed to transform application
              development. Built on ReactJS and NodeJS, it leverages a modern
              tech stack to streamline the development process across diverse
              industries, reducing time and costs while maintaining high-quality
              standards.
            </p>
            <button
              className="btn btn-primary btn-lg mb-5"
              style={{
                backgroundColor: "#010c54",
                borderColor: "#010c54",
                fontWeight: "bold",
              }}
              onClick={handleOpenModal}
            >
              Try now
            </button>
            <DemoModel
              show={showModal}
              handleClose={handleCloseModal}
              dropDrownValue={["RDMP"]}
            />
          </Col>

          <Col
            lg={4}
            md={6}
            sm={12}
            className="mb-3 d-flex justify-content-center align-items-center position-relative"
          >
            {!isPlaying ? (
              <div
                onClick={handlePlayVideo}
                style={{
                  height: "400px",
                  width: "100%",
                  backgroundImage: `url(${demo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaPlay />
                </div>
              </div>
            ) : (
              <video
                controls
                className="img-fluid rounded"
                style={{
                  height: "400px",
                  width: "100%",
                }}
                alt="Cloudera Data Platform"
              >
                <source src={dummy} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RdmpHeroContent;
