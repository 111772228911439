import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bimac from "../../../Images/products/edms/bimac.png";
import finacial from "../../../Images/products/edms/finacial.png";
import udanga from "../../../Images/products/edms/udanga.png";
import knomadix from "../../../Images/products/edms/knomadix.png";
import gallop from "../../../Images/products/edms/gallop.png";

const Client = () => {
  let Customers = [
    { image: bimac },
    { image: finacial },
    { image: udanga },
    { image: gallop },
    { image: knomadix },
  ];

  return (
    <div className="py-5">
      <Container>
        <div className="text-center py-2">
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 text-center my-5 py-4"
          >
            Our Clients
          </p>
        </div>
        <Row className="g-4 d-flex justify-content-center">
          {Customers.map((e, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className="d-flex justify-content-center"
            >
              <div
                className="p-3 d-flex align-items-center justify-content-center rounded animate__animated animate__fadeIn animate__delay-1s"
                style={{
                  maxWidth: "250px",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    objectFit: "contain", // Ensure the image fits within the box
                    maxWidth: "100%", // Scale down if necessary
                    maxHeight: "150px", // Set a height limit for consistency
                    borderRadius: "10px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  src={e.image}
                  alt="customer"
                  className="animate__animated animate__zoomIn animate__delay-2s"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Hover Effect: Zoom In */}
      <style jsx="true">
        {`
          .animate__zoomIn:hover {
            transform: scale(1.05);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
          }

          .animate__zoomIn:active {
            transform: scale(1);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          }
        `}
      </style>
    </div>
  );
};

export default Client;
