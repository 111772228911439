import React, { useRef } from "react";
import styles from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import "../../Screens/landingPage/style.css";
import EffortsOfIsms from "./EffortsOfIsms";
import FeaturesOfIsms from "./FeaturesOfIsms";
import bg from "../../Images/MoreInfo.png";
import IsmsAssist from "./IsmsAssist";
import IntroISMS from "./IntroISMS";
import WhyChoose from "./WhyChoose";
import IsmsPoised from "./IsmsPoised";
import "../Products/style.css";
import { useMediaQuery } from "react-responsive";

const Isms = () => {
  const effortsRef = useRef(null);
  const featuresRef = useRef(null);
  const ismsAssistRef = useRef(null);
  const introISMSRef = useRef(null);
  const whychooseISMSRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span style={{ fontSize: isMobile ? "20px" : "40px" }}>
                          Empowering neurodivergent children through a seamless
                          platform that unites personalized assessments,
                          real-time tracking, and collaborative support—giving
                          every child the tools to thrive
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div
        className="container rounded-2 my-5 pt-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-md-around py-2 fw-bold h5"
          style={{ gap: "15px" }}
        >
          <p
            onClick={() => scrollToSection(introISMSRef)}
            style={{
              cursor: "pointer",
              color: "white",
              textAlign: "center",
            }}
          >
            ISMS
          </p>
          <p
            onClick={() => scrollToSection(effortsRef)}
            style={{
              cursor: "pointer",
              color: "white",
              textAlign: "center",
            }}
          >
            Efforts of ISMS
          </p>
          <p
            onClick={() => scrollToSection(featuresRef)}
            style={{
              cursor: "pointer",
              color: "white",
              textAlign: "center",
            }}
          >
            Features of ISMS
          </p>
          <p
            onClick={() => scrollToSection(whychooseISMSRef)}
            style={{
              cursor: "pointer",
              color: "white",
              textAlign: "center",
            }}
          >
            Why Choose ISMS
          </p>
          <p
            onClick={() => scrollToSection(ismsAssistRef)}
            style={{
              cursor: "pointer",
              color: "white",
              textAlign: "center",
            }}
          >
            ISMS Assist Connect
          </p>
        </div>
      </div>

      <div ref={introISMSRef}>
        <IntroISMS />
      </div>
      <div ref={effortsRef}>
        <EffortsOfIsms />
      </div>
      <div ref={featuresRef}>
        <FeaturesOfIsms />
      </div>
      <div ref={whychooseISMSRef}>
        <WhyChoose />
      </div>
      <div ref={ismsAssistRef}>
        <IsmsAssist />
      </div>
      <div ref={ismsAssistRef}>
        <IsmsPoised />
      </div>
    </div>
  );
};

export default Isms;
