import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import HrmsHeroContent from "./HrmsHeroContent";
import HrmsFeatures from "./HrmsFeatures";
import AdvantagesHrms from "./Advantages";
import HrmsPoised from "./HrmsPoised";
import BookDemo from "./BookDemo";
import { HrmsWhyChoosed } from "./HrmsWhyChoosed";
import { useMediaQuery } from "react-responsive";

const Hrms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "20px" : "40px" }}
                          className="text-uppercase"
                        >
                          Transform workforce management with HRMS by TTIPL –
                          your all-in-one solution for recruitment, engagement,
                          and performance tracking.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <HrmsHeroContent />
          <HrmsFeatures />
          <AdvantagesHrms />
          <HrmsWhyChoosed />
          <HrmsPoised />
          <BookDemo />
        </div>
      </div>
    </div>
  );
};

export default Hrms;
