import React, { useEffect } from "react";
import styles from "./style.module.css";
import RdmpHeroContent from "./RdmpHeroContent";
// import RdmpCustomer from "./RdmpCustomer";
import WhyChoosRdmp from "./WhyChooseRdmp";
import FeatureOfRdmp from "./FeaturesOfRdmp";
import ExperiencetheRdpm from "./ExperiencetheRdmp";
import WhoCanBenefit from "./WhoCanBenifit";
import { Col, Container, Row } from "react-bootstrap";
import "../../Products/style.css";
import BookDemo from "./BookDemo";
import { useMediaQuery } from "react-responsive";

const Rdmp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "25px" : "40px" }}
                          className="text-uppercase"
                        >
                          RDMP: Revolutionizing App Development with Speed and
                          Precision
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <RdmpHeroContent />
      <WhyChoosRdmp />
      {/* <RdmpCustomer /> */}
      <FeatureOfRdmp />
      <WhoCanBenefit />
      <ExperiencetheRdpm />
      <BookDemo />
    </div>
  );
};

export default Rdmp;
