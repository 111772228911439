import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../../src/Images/products/edms/access.gif";
import Convenient from "../../../../src/Images/products/edms/powersearch.gif";
import Personalized from "../../../../src/Images/products/edms/workflow.gif";
import Measurable from "../../../../src/Images/products/edms/document.gif";
import Accessible from "../../../../src/Images/products/edms/meta.gif";
import Resources from "../../../../src/Images/products/edms/Versioning.gif";

const FeaturesOfEdms = () => {
  const features = [
    {
      name: "Access Control Management",
      img: Assessments,
      content:
        "Achieve comprehensive access control with tailored permissions. Easily share with groups and collaborate seamlessly with External Applications.",
    },
    {
      name: "Powerful Search With Multilingual OCR",
      img: Convenient,
      content:
        "Our software uses Google's VISION API for OCR, enabling fast access to files by name, metadata, and content types like PDFs, Docx, and scans. ",
    },
    {
      name: "Workflows, eSigning & Automated Retention",
      img: Personalized,
      content:
        "Send documents seamlessly for approval, acknowledgment, or e-signatures, with flexible workflow options. Customize retention periods for folders or files as needed.",
    },
    {
      name: "Document Numbering, Notifications & Audit",
      img: Measurable,
      content:
        "Automate document numbering and receive notifications for changes, ensuring accountability with audit trails for every user action.",
    },
    {
      name: "Custom Metadata & File Linking",
      img: Accessible,
      content:
        "Customize document management with tailored metadata fields and folder-level templates for streamlined processes, ensuring consistent data capture and document connections. capture",
    },
    {
      name: "Versioning",
      img: Resources,
      content:
        "Track document versions and ensure conflict- free edits with our comprehensive version control. Maintain document integrity and accuracy throughout their lifecycle.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-3">
      <div className="container text-center my-2">
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            Features of EDMS
          </p>
        </div>
        <div className="row gy-4 pb-4">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center bt-3"
            >
              <div
                className="card shadow-md"
                style={{ width: "20rem", borderTop: "8px solid #ff6300 " }}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "80%",
                      height: "auto",
                      padding: "12%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div className="card-body">
                  <h5
                    className="card-title  fw-bold pb-2  h5"
                    style={{ textAlign: "center", color: "#ff6300 " }}
                  >
                    {item.name}
                  </h5>
                  <p
                    className="card-text text-left fw-bold h5"
                    style={{ textAlign: "center", color: "#010c54" }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesOfEdms;
