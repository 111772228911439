import React from "react";

const IsmsPoised = () => {
  return (
    <div>
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <div>
              {" "}
              <p
                style={{ color: "#ff6300", fontWeight: "bold" }}
                className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4 "
              >
                Why ISMS is Poised for Market Success?
              </p>
            </div>

            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                Growing Demand in Neurodivergent Support:
              </span>{" "}
              With the increasing prevalence of autism spectrum disorder (ASD)
              and other neurodevelopmental needs, the demand for structured,
              digital intervention systems has never been greater. The global
              digital health market, particularly in education and
              neurodevelopmental support, is expanding rapidly, with a growth
              rate of 20% annually, making ISMS a timely and essential solution.
            </p>
            <p className="h5 fw-bold pb-3">
              <span style={{ color: "#010c54" }} className="fw-bold">
                High-Impact Solution for Schools and Families:
              </span>{" "}
              ISMS bridges a critical gap in the market by providing accessible,
              structured care for neurodivergent children. Schools report
              significant challenges in addressing these students’ needs, while
              parents struggle to find cohesive support systems. ISMS simplifies
              these processes, bringing together essential support through a
              user-friendly interface that encourages consistent engagement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsmsPoised;
