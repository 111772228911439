import React from "react";
import dummy from "../../Images/ismslogin.jpeg";
import dummy2 from "../../Images/ismsbyttipl.jpeg";
import { Col, Container, Row } from "react-bootstrap";
import ismss from "../../Images/products/isms/ismss.png";
const IntroISMS = () => {
  return (
    <>
      <div>
        <Container className="pt-5">
          <Row className="align-items-center mb-5 py-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-3 justify-content-around px-lg-4"
            >
              <div className="pb-3">
                <div
                  className="d-flex align-items-center"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: "0 0 20%", textAlign: "center" }}>
                    <img
                      src={ismss}
                      alt="isms"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div style={{ flex: "0 0 80%" }}>
                    <p
                      className="h1"
                      style={{
                        color: "#ff6300",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Intervention Service Management System
                    </p>
                  </div>
                </div>
              </div>

              <p
                className="pb-4 h5 fw-bold"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                ISMS (Intervention Service Management System) is a
                transformative platform developed by TTIPL to support
                neurodivergent children with personalized care, real-time
                tracking, and collaborative tools. Created with input from
                educators, therapists, and parents, ISMS is designed to address
                the unique needs of children on the neurodivergent spectrum by
                offering a seamless, centralized solution for assessments,
                intervention planning, and daily progress monitoring.
              </p>
            </Col>

            {/* Image Column */}
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-3 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <img
                src={dummy}
                alt="Cloudera Data Platform"
                className="img-fluid w-100 h-100 object-fit-cover rounded"
                style={{ objectFit: "cover", height: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#F4FAFF" }} className="py-lg-4">
        <Container>
          <Row className="align-items-center mt-5 py-5">
            {/* Image Column with red background */}
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-3 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <img
                src={dummy2}
                alt="Cloudera Data Platform"
                className="img-fluid w-100 h-100 object-fit-cover rounded"
                style={{
                  objectFit: "cover",
                  height: "100%",
                  backgroundColor: "red",
                }}
              />
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-3 justify-content-around px-lg-4"
            >
              <div className="pb-3">
                <div>
                  <p
                    className=" display-4 "
                    style={{ color: "#ff6300", fontWeight: "bold" }}
                  >
                    ISMS by TTIPL
                  </p>
                </div>
              </div>

              <p
                className="pb-4 h5 fw-bold"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                ISMS (Intervention Service Management System) is an innovative,
                data-driven platform designed to revolutionize care for
                neurodivergent children. Developed by TTIPL in partnership with
                Elina, a leading name in neurodivergent support, ISMS provides a
                seamless blend of personalized intervention, real-time tracking,
                and a collaborative ecosystem. This unique approach empowers
                parents, educators, and therapists to work together more
                effectively, fostering an environment where each child’s
                potential is realized. For investors, ISMS offers a compelling
                opportunity to invest in a socially impactful solution within
                the expanding field of neurodivergent support technology.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default IntroISMS;
