import React, { useEffect } from "react";
import styles from "./styles.module.css";
import TTIPLMOREINFOLOGO from "../../Images/products/talent_takeaways.png";

import "aos/dist/aos.css";
const MoreInfo = () => {
  return (
    <div className={styles.MoreInfoHeader}>
      <div className={styles.MoreInfoHeader1}>
        <img
          data-aos-duration="1000"
          data-aos="fade-down"
          src={TTIPLMOREINFOLOGO}
          className={styles.MoreInfoHeader2}
          alt="moreinfo"
        />
        <div
          className={styles.MVPContent}
          data-aos-duration="1000"
          data-aos="fade-up"
        >
          <p>Are you a budding startup? </p>
          <p>Do you need an expert support?</p>
          <p>Want to grow exponentially?</p>
        </div>
      </div>
    </div>
  );
};
export default MoreInfo;
