import React, { useState } from "react";
import styles from "./styles.module.css";
// import TTIPLLOGO from "../../Images/TTIPLlogo.png";
import TTIPLLOGO from "../../Images/products/talent_takeaways.png";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

import { useNavigate } from "react-router-dom";
import Popup from "../../Commpoents/Modal/popup";
const Header = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { navbar } = props;
  return (
    <header>
      {!isMobile && (
        <Container
          className={
            navbar === true ? styles.MainheaderScroll : styles.landingMainheader
          }
        >
          <div className={styles.container}>
            <img
              onClick={() => handleClick()}
              src={TTIPLLOGO}
              style={{ cursor: "pointer", padding: "8px" }}
              id="Mainhead"
              className={styles.image}
              alt="logo"
            />
          </div>
          <div className={styles.ContactusMain}>
            <Button href="#footer" className={styles.Contact_Us}>
              Contact Us
            </Button>
          </div>

          {open && <Popup open={open} handleClose={() => setOpen(false)} />}
        </Container>
      )}
    </header>
  );
};
export default Header;
