import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";

const ProductReview = () => {
  return (
    <div className="py-3" style={{ background: "#F4FAFF" }}>
      <Container>
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            What customers say about Products?
          </p>
        </div>
        <Row className="g-4">
          {[
            {
              title: "Knomadix AI",
              content:
                "Talent Takeaways Info Tech Pvt Ltd. delivered an outstanding product with Knomadix AI. Their expertise and commitment to creating a personalized learning platform exceeded our expectations. Great work by the team!",
              author: "Ramesh Balan",
            },
            {
              title: "Tamil Nadu Apex Skill Development Centre for Logistics",
              content:
                "The team at Talent Takeaways Info Tech Pvt Ltd. built an impressive platform for TNASDL, enhancing training with advanced technology. Their professionalism and execution are commendable.",
              author: "Naushin",
            },
            {
              title: "Elina Services",
              content:
                "Talent Takeaways Info Tech Pvt Ltd. did a fantastic job bringing Elina Services to life. Their dedication and technical skills have made a lasting impact on our mission.",
              author: "Ramalakshmi",
            },
            {
              title: "Naan Mudhalvan",
              content:
                "The Naan Mudhalvan platform by Talent Takeaways Info Tech Pvt Ltd. is exceptional. Their innovative approach and efficient delivery have made it a valuable resource for students.",
              author: "Giridharan",
            },
          ].map((review, index) => (
            <Col xs={12} md={6} lg={6} key={index}>
              <div
                className="p-3  rounded bg-white d-flex flex-column justify-content-between"
                style={{
                  height: "100%",
                  borderTop: "4px solid #ff6300",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "none",
                }}
              >
                <div className="mb-3">
                  <FaQuoteLeft style={{ fontSize: "24px", color: "#ff6300" }} />
                  <h3 className="fw-bold mt-3" style={{ color: "#010c54" }}>
                    {review.title}
                  </h3>
                  <div
                    className="rounded"
                    style={{
                      backgroundColor: "#ff6300",
                      height: "4px",
                      width: "50px",
                      marginBottom: "10px",
                    }}
                  ></div>
                  <p className="mb-3 fw-lighter h5">{review.content}</p>
                </div>
                <h4 className="fw-bold" style={{ color: "#ff6300" }}>
                  - {review.author}
                </h4>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductReview;
