import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Questionnaires from "../../../src/Images/products/isms/Parent Questionnaires.jpg";
import Advocacy from "../../../src/Images/products/isms/Parent Advocacy meeting.jpg";
import Visual from "../../../src/Images/products/isms/Parent-Provided Visual Documentation.jpg";
import Organizational from "../../../src/Images/products/isms/Customizable Reports.jpg";

const IsmsAssist = () => {
  const assist = [
    {
      img: Questionnaires,
      head: (
        <span>
          <span style={{ color: "#ff6300" }}>Parent Questionnaires</span>:
          <span style={{ color: "#010c54" }}> Analysis & Evaluation.</span>
        </span>
      ),
      content:
        "These questionnaires are designed for parents to provide essential details about their child, enabling processing, analysis, and evaluation of information. The collected data helps professionals gain insights into the child's needs, allowing for informed decision-making and tailored support.",
    },
    {
      img: Advocacy,
      head: (
        <span>
          <span style={{ color: "#ff6300" }}>Parent Advocacy Meeting:</span>
          <span style={{ color: "#010c54" }}>
            Empowering Voices, Building Support.
          </span>
        </span>
      ),
      content:
        "Parent advocacy meetings bring together parents of children with special needs to collaboratively address educational and support-related issues, sharing information and advocating collectively for their children's rights and well-being. These meetings provide a supportive environment for empowerment, resource sharing, and effective communication between parents and professionals.",
    },
    {
      img: Visual,
      head: (
        <span>
          <span style={{ color: "#ff6300" }}>
            Parent-Provided Visual Documentation:
          </span>
          <span style={{ color: "#010c54" }}>
            Evaluation and finding Solutions.
          </span>
        </span>
      ),
      content:
        "Parents capture video recordings of their child's behavior, skills, or challenges and share them with professionals for further assessment and collaborative solutions. These recordings provide valuable visual information that aids in understanding the child's development, facilitating targeted interventions and recommendations.",
    },
    {
      img: Organizational,
      head: (
        <span>
          <span style={{ color: "#ff6300" }}>Customizable Reports:</span>
          <span style={{ color: "#010c54" }}>
            Tailoring to Organizational Needs
          </span>
        </span>
      ),
      content:
        "Reports can be personalized and adapted to fulfill the specific needs and requirements of an organization by utilizing diverse filters, sorting options, and layout designs. This flexibility allows for targeted data analysis and presentation, empowering decision-makers to extract valuable insights and make informed choices.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="py-3">
      <Container>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          ISMS Assist Connect
        </p>

        {assist.map((item, index) => (
          <Row
            key={index}
            className={`mb-5 pb-lg-5 d-flex align-items-center ${
              index % 2 === 0 ? "flex-md-row" : "flex-md-row-reverse"
            }`}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            {/* Responsive layout without margins for mobile */}
            <Col xs={12} md={4} className="mb-3">
              <img
                src={item.img}
                alt=""
                className="img-fluid rounded"
                style={{
                  transition: "transform 0.3s ease",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              />
            </Col>
            <Col xs={12} md={8}>
              <div>
                <p className="h2 fw-bold " style={{ color: "#010c54" }}>
                  {item.head}
                </p>
                <div
                  style={{
                    backgroundColor: "#ff6300",
                    height: "3px",
                    width: "10%",
                    marginBottom: "10px",
                  }}
                ></div>
                <p
                  className="h5 fw-bold text-justify"
                  style={{ textAlign: "justify" }}
                >
                  {item.content}
                </p>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default IsmsAssist;
