import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import "../../Products/style.css";
import Overview from "./Overview";
import FeatureOfSrm from "./FeatureOfSrm";
import { Process } from "./Process";
import AdditonalFeature from "./AdditionalFeature";
import BookDemo from "./BookDemo";
import { SrmHeroContent } from "./SrmHeroContent";
import { useMediaQuery } from "react-responsive";

const Srm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "26px" : "40px" }}
                          className="text-uppercase"
                        >
                          Boasts innovative and robust features designed to
                          optimize and streamline the procurement process
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <SrmHeroContent />
          <Overview />
          <FeatureOfSrm />
          <Process />
          <AdditonalFeature />
          <BookDemo />
        </div>
      </div>
    </div>
  );
};

export default Srm;
