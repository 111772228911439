import React, { useEffect } from "react";
import "./ExperiencetheRdpm.css"; // Import the custom CSS file for animation

const ExperiencetheRdpm = () => {
  useEffect(() => {
    // Select all elements with the class 'fade-in'
    const elements = document.querySelectorAll(".fade-in");

    // Create the Intersection Observer
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // When the element is in the viewport
          if (entry.isIntersecting) {
            // Add the 'animated' class to trigger animation
            entry.target.classList.add("animated");
            // Stop observing the element after it has animated (optional)
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5, // Trigger animation when 50% of the element is in view
      }
    );

    // Observe each element with the class 'fade-in'
    elements.forEach((el) => observer.observe(el));

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="container my-5">
      <div className="row justify-content-center text-center">
        <div className="col-md-10 col-lg-8">
          <h1
            className="display-4 fw-bold mb-4 fade-in"
            style={{ color: "#ff6300" }}
          >
            Experience the RDMP Advantage
          </h1>
          <div className="py-lg-3">
            <div>
              <span className="h4 fw-bold fade-in" style={{ color: "#010c54" }}>
                RDMP is not just a development platform - it’s a catalyst for
                innovation. Whether launching new projects or scaling existing
                ones, RDMP equips your team with the tools to deliver
                exceptional results, faster.
              </span>
              <div className="py-5">
                <span
                  className="h4 fw-bold fade-in pt-4"
                  style={{ color: "#010c54" }}
                >
                  Discover how RDMP can transform your development process
                  today!
                </span>
              </div>
            </div>
          </div>
          {/* <button
            className="btn btn-primary btn-lg mt-3 fade-in"
            style={{
              backgroundColor: "#010c54", // Initial color
              borderColor: "#010c54", // Border color
              animation:
                "zoomInOut 3s ease-in-out infinite, colorChange 3s ease-in-out infinite", // Infinite animations
            }}
          >
            Book your Demo
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ExperiencetheRdpm;
