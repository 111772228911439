import React, { useState, useRef } from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { FaExclamationCircle } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FooterModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.userName.trim()) {
      newErrors.userName = "Name is required.";
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Valid email is required.";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }
    if (!captchaValue) {
      newErrors.captcha = "Please complete the CAPTCHA.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      await axios.post(
        "https://fia-uganda-edrms.com:4047/api/contact_form",
        formData
      );
      Swal.fire("Success", "Your message has been sent!", "success");
      setFormData({ userName: "", email: "", phoneNumber: "", message: "" });
      setCaptchaValue(null);
      recaptchaRef.current.reset();
      handleClose();
    } catch (error) {
      Swal.fire("Error", "There was an error sending your message.", "error");
    } finally {
      setLoading(false);
    }
  };

  const commonInputStyle = {
    width: "100%",
    height: "40px",
    border: "2px solid #010c54",
    marginTop: "8px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "8px",
    outline: "none",
  };

  const errorStyle = {
    color: "#e74c3c",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  return (
    <Modal show={open} onHide={handleClose} centered size="md" className="">
      <div
        style={{
          textAlign: "center",
          padding: "15px",
          backgroundColor: "#010c54",
          color: "#fff",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontWeight: "bold", margin: 0 }}>Contact Us</h4>
        <button
          onClick={handleClose}
          style={{
            background: "none",
            border: "none",
            color: "#fff",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
      </div>

      <Modal.Body>
        <Container className="px-5">
          <form onSubmit={handleSubmit}>
            <Row className="my-4">
              <Col className="py-2">
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  placeholder="Enter your Name"
                  style={commonInputStyle}
                />
                {errors.userName && (
                  <div style={errorStyle}>
                    <FaExclamationCircle style={{ marginRight: "5px" }} />
                    {errors.userName}
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="py-2">
                <label>
                  Email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email"
                  style={commonInputStyle}
                />
                {errors.email && (
                  <div style={errorStyle}>
                    <FaExclamationCircle style={{ marginRight: "5px" }} />
                    {errors.email}
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="py-2">
                <label>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <PhoneInput
                  country="in"
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData({ ...formData, phoneNumber: value })
                  }
                  inputStyle={{
                    ...commonInputStyle,
                    paddingLeft: "50px",
                  }}
                  className="my-2"
                  containerStyle={{ width: "100%" }}
                />
                {errors.phoneNumber && (
                  <div style={errorStyle}>
                    <FaExclamationCircle style={{ marginRight: "5px" }} />
                    {errors.phoneNumber}
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="py-2">
                <label>
                  Message <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter your Message"
                  rows="4"
                  style={commonInputStyle}
                ></input>
                {errors.message && (
                  <div style={errorStyle}>
                    <FaExclamationCircle style={{ marginRight: "5px" }} />
                    {errors.message}
                  </div>
                )}
              </Col>
            </Row>

            <div className="mb-3 text-center">
              <ReCAPTCHA
                sitekey="6Lc3HaYqAAAAADGdnvNGOgIttYtkZJLAOZHdmUpQ"
                onChange={handleCaptchaChange}
                ref={recaptchaRef}
              />
              {errors.captcha && (
                <div style={errorStyle}>
                  <FaExclamationCircle style={{ marginRight: "5px" }} />
                  {errors.captcha}
                </div>
              )}
            </div>

            <div className="text-center py-3">
              <Button
                type="submit"
                className="px-5 fw-bold"
                disabled={loading}
                style={{
                  backgroundColor: loading ? "#ccc" : "#ff6300",
                  borderRadius: "20px",
                  border: "none",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default FooterModal;
