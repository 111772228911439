import React, { useState } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Input } from "reactstrap";
import { FaExclamationCircle } from "react-icons/fa";
import Swal from "sweetalert2-react";

const DemoModel = ({ show, handleClose, dropDrownValue }) => {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    company: "",
    products: dropDrownValue || [],
  });
  const recaptchaRef = React.createRef();
  const [errors, setErrors] = useState({
    phone: "",
    email: "",
    company: "",
    products: "",
    captcha: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setFormData({
      ...formData,
      products: selectedValues.length > 0 ? selectedValues : [],
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.company) newErrors.company = "Organization Name is required.";
    if (!/^\d+$/.test(formData.phone))
      newErrors.phone = "Phone number must be numeric.";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Please enter a valid email address.";
    if (formData.products.length === 0)
      newErrors.products = "At least one product must be selected.";
    if (!captchaValue) newErrors.captcha = "Please complete the CAPTCHA.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);
    const payload = {
      phone: formData.phone,
      email: formData.email,
      companyName: formData.company,
      productNames: formData.products,
    };

    fetch("https://fia-uganda-edrms.com:4047/api/demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(() => {
        setFormData({ phone: "", email: "", company: "", products: [] });
        setSuccessMessage(
          "Thank you for your submission! We will get in touch with you soon."
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlertMessage("There was an error submitting the form.");
        setShowErrorAlert(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const commonInputStyle = {
    width: "100%",
    height: "40px",
    border: "2px solid  #010c54",
    marginTop: "8px",
    padding: "15px",
    fontSize: "16px",
    borderRadius: "8px",
    outline: "none",
  };

  const inputFocusStyle = {
    borderBottom: "2px solid #010c54",
    outline: "none",
  };

  const errorStyle = {
    color: "#e74c3c",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Swal
        show={showErrorAlert}
        title="Error"
        text={alertMessage}
        type="error"
        onConfirm={() => setShowErrorAlert(false)}
      />
      <div
        style={{
          textAlign: "center",
          padding: "15px",
          backgroundColor: "#010c54",
          color: "#fff",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <div>
          <h4 style={{ fontWeight: "bold", margin: 0 }}>Book a Demo</h4>
        </div>
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            background: "none",
            border: "none",
            color: "#fff",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          X
        </button>
      </div>

      <Modal.Body>
        <Container fluid>
          {successMessage ? (
            <div className="text-center">
              <h4 className="py-3">{successMessage}</h4>
              <Button
                variant="primary"
                onClick={handleClose}
                className="my-3 fw-bold"
                style={{
                  backgroundColor: "#ff6300",
                  borderRadius: "20px",
                  border: "none",
                }}
              >
                Close
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="mt-3 mx-5">
              <Row className="g-3 mt-3">
                <Col xs={12} sm={12}>
                  <label className="py-2">
                    Organization Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    placeholder="Enter Organization Name"
                    style={{
                      ...commonInputStyle,
                      ...(formData.company ? inputFocusStyle : {}),
                    }}
                  />
                  {errors.company && (
                    <div style={errorStyle}>
                      <FaExclamationCircle style={{ marginRight: "5px" }} />
                      {errors.company}
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={12}>
                  <label className="py-2">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your Email"
                    style={{
                      ...commonInputStyle,
                      ...(formData.email ? inputFocusStyle : {}),
                    }}
                  />
                  {errors.email && (
                    <div style={errorStyle}>
                      <FaExclamationCircle style={{ marginRight: "5px" }} />
                      {errors.email}
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="g-3 mt-3">
                <Col xs={12} sm={12}>
                  <label className="py-2">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={formData.phone}
                    onChange={(value) =>
                      setFormData({ ...formData, phone: value })
                    }
                    inputStyle={{
                      ...commonInputStyle,
                      paddingLeft: "50px",
                    }}
                    containerStyle={{ width: "100%" }}
                  />
                  {errors.phone && (
                    <div style={errorStyle}>
                      <FaExclamationCircle style={{ marginRight: "5px" }} />
                      {errors.phone}
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={12}>
                  <label className="py-2">
                    Select Products <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    type="select"
                    id="multiple-select"
                    name="multiple-select"
                    multiple
                    onChange={handleSelectChange}
                    value={formData.products}
                    style={{
                      width: "100%",
                      height: "100px",
                      borderRadius: "8px",
                      padding: "8px",
                      border: "2px solid #010c54",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                      transition: "all 0.3s ease",
                    }}
                  >
                    {["RDMP", "HRMS", "LIMS", "LMS", "SRM", "EAMS", "EDMS"].map(
                      (product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      )
                    )}
                  </Input>
                  {errors.products && (
                    <div style={errorStyle}>
                      <FaExclamationCircle style={{ marginRight: "5px" }} />
                      {errors.products}
                    </div>
                  )}
                </Col>
              </Row>

              <div className="mt-3">
                <ReCAPTCHA
                  sitekey="6Lc3HaYqAAAAADGdnvNGOgIttYtkZJLAOZHdmUpQ"
                  onChange={handleCaptchaChange}
                  ref={recaptchaRef}
                />
                {errors.captcha && (
                  <div style={errorStyle}>
                    <FaExclamationCircle style={{ marginRight: "5px" }} />
                    {errors.captcha}
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center my-4">
                <Button
                  type="submit"
                  className="px-5 fw-bold"
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: isSubmitting ? "#ccc" : "#ff6300",
                    borderRadius: "20px",
                    border: "none",
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DemoModel;
