import React from "react";

export const EfficientsOfEdms = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5">
      <div className="container pt-3">
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Revolutionize Your Document Management with DIGITEC EDMS
        </p>
      </div>
      <div className="container pb-3 px-sm-2">
        <div className="row g-4 justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                1
              </span>
              User-friendly Interface
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                2
              </span>
              Customized Solutions
            </p>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                3
              </span>
              Robust Security
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                4
              </span>
              Seamless Integration
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p
              className="bg-white p-4 rounded-5 h5 fw-bold text-left"
              style={{ color: "#010c54" }}
            >
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#ff6300",
                  color: "white",
                }}
                className="p-3 me-2"
              >
                5
              </span>
              Expert Support
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
