import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Digitec = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }}>
      <div>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Empowering Learners
        </p>
      </div>
      <Container className="pb-5">
        <Row className="gy-4">
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white d-flex flex-column">
              <p
                className="fw-bold h4 text-center flex-shrink-0"
                style={{
                  color: "#ff6300",
                  minHeight: "4rem", // Ensures all headings have the same height
                }}
              >
                Efficiency and Productivity
              </p>
              <p
                className="h5 fw-bold px-2 py-3 flex-grow-1"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                Effortlessly streamline document management with DIGITECH'S
                EDMS, featuring version control, automated workflows, and
                centralized access. Save time and enhance collaboration for
                efficient task completion.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white d-flex flex-column">
              <p
                className="fw-bold h4 text-center flex-shrink-0"
                style={{
                  color: "#ff6300",
                  minHeight: "4rem", // Ensures all headings have the same height
                }}
              >
                Compliance and Risk Management
              </p>
              <p
                className="h5 fw-bold px-2 py-3 flex-grow-1"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                DIGITECH'S EDMS equips clients with tools to meet strict
                regulatory standards like HIPAA and GDPR. With features such as
                access controls and encryption, it safeguards sensitive data and
                ensures compliance.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="p-3 border rounded shadow h-100 bg-white d-flex flex-column">
              <p
                className="fw-bold h4 text-center flex-shrink-0"
                style={{
                  color: "#ff6300",
                  minHeight: "4rem", // Ensures all headings have the same height
                }}
              >
                Enhanced Collaboration
              </p>
              <p
                className="h5 fw-bold px-2 py-3 flex-grow-1"
                style={{ textAlign: "justify", color: "#010c54" }}
              >
                DIGITECH'S EDMS encourages team collaboration by offering a
                centralized platform for document storage and editing. It
                enables real-time access and interaction with documents,
                promoting seamless teamwork and knowledge exchange across
                locations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Digitec;
