import React from "react";

import { Container, Row, Col } from "react-bootstrap";
export const HrmsWhyChoosed = () => {
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="py-3">
      <Container>
        <div className="my-3 pt-2">
          <p
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-2 "
            style={{ fontWeight: "bold", color: "#ff6300" }}
          >
            Why Choose HRMS?
          </p>
        </div>
        <Row className="g-6 pb-5  ">
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Empowering Employees:
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              HRMS equips employees with easy access to their personal data,
              leave balances, performance reviews, and career development
              opportunities. Employees gain transparency and control over their
              work life, leading to increased satisfaction and engagement.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Supporting HR Teams:
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              HRMS streamlines various HR functions such as recruitment,
              onboarding, performance management, and leave management. HR teams
              benefit from automated processes, real-time data analytics, and
              simplified reporting, enabling them to make informed decisions and
              improve efficiency.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="px-lg-4">
            <div
              className="d-flex flex-column justify-content-top "
              style={{ height: "70px" }}
            >
              <p className="h3 fw-bold  mb-2" style={{ color: "#010c54" }}>
                Enhancing Management Outcomes:
              </p>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
            </div>
            <br />
            <p
              className="h5 fw-bold pe-3 text-justify"
              style={{ textAlign: "justify" }}
            >
              For managers, HRMS offers an integrated tool to track team
              performance, manage leave, and streamline communication. By having
              access to key data and insights, managers can provide timely
              feedback and foster a productive work environment.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
