import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../src/Images/products/features/Assessments.gif";
import Convenient from "../../../src/Images/products/features/Planning.gif";
import Personalized from "../../../src/Images/products/features/Activity.gif";
import Measurable from "../../../src/Images/products/features/Stakeholder.gif";
import Accessible from "../../../src/Images/products/features/time.gif";
import Resources from "../../../src/Images/products/features/eco.gif";

const FeaturesOfIsms = () => {
  const features = [
    {
      name: "Personalized Assessments and Adaptive Planning",
      img: Assessments,
      content:
        "ISMS uses individualized assessments to guide targeted intervention strategies, creating a roadmap that adapts to each child’s development. This dynamic capability allows for meaningful progress and ensures that interventions are always relevant and effective.",
    },
    {
      name: "Referral Planning",
      img: Convenient,
      content:
        "Through an intelligent referral system, ISMS connects children and families with relevant professionals based on the child’s assessment results. This feature streamlines the coordination of specialized resources, helping parents and schools access the support they need efficiently.",
    },
    {
      name: "Daily Activity Monitoring",
      img: Personalized,
      content:
        "ISMS tracks day-to-day progress, enabling parents, educators, and therapists to monitor the effectiveness of interventions and adjust strategies as needed. This real-time insight helps ensure that every effort is aligned with the child’s evolving needs.",
    },
    {
      name: "Stakeholder Collaboration",
      img: Measurable,
      content:
        "With built-in tools for communication and data sharing, ISMS bridges gaps between parents, schools, and therapists. This collaborative approach ensures consistency in care, reduces communication barriers, and fosters a supportive network around each child.",
    },
    {
      name: "Real-Time Tracking for Proactive Adjustments",
      img: Accessible,
      content:
        "By enabling real-time progress tracking, ISMS allows educators and therapists to make informed adjustments quickly, optimizing the impact of each intervention. This data-driven approach enhances transparency and maximizes outcomes for neurodivergent children.",
    },
    {
      name: "Collaborative Ecosystem for Unified Support",
      img: Resources,
      content:
        "ISMS fosters seamless communication between parents, schools, and therapists, ensuring that every stakeholder is informed and engaged. This collaborative ecosystem reduces fragmentation and builds a supportive network that enhances every aspect of a child’s development.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5">
      <div className="container text-center my-2">
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            Features of ISMS
          </p>
        </div>
        <div className="row gy-4">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center bt-3"
            >
              <div
                className="card shadow-md"
                style={{ width: "20rem", borderTop: "8px solid #ff6300 " }}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "80%",
                      height: "auto",
                      padding: "12%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div className="card-body">
                  <h5
                    className="card-title  fw-bold pb-2 "
                    style={{ textAlign: "center", color: "#ff6300" }}
                  >
                    {item.name}
                  </h5>
                  <p
                    className="card-text text-left fw-bold"
                    style={{ textAlign: "justify", color: "#010c54" }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesOfIsms;
