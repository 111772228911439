import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dummyImage from "../../../Images/products/rdmp/benefits.jpg"; // Replace with your image path
import "./WhoCanBenefit.css"; // Import the custom CSS file

const WhoCanBenefit = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(".fade-in");
    elements.forEach((el) => {
      el.classList.add("animated");
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#F4FAFF" }}>
      <Container className="py-5">
        <div className="text-center my-4">
          <p
            className="display-4 mx-4 py-3"
            style={{ color: "#ff6300", fontWeight: "bold" }}
          >
            Who Can Benefit?
          </p>
        </div>
        <Row className="align-items-center">
          {/* Image Column */}
          <Col lg={6} md={6} sm={12} className="text-center mb-3">
            <img
              src={dummyImage}
              alt="Who Can Benefit"
              className="img-fluid rounded"
            />
          </Col>

          {/* Content Column with animation */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 py-md-3 py-lg-3 d-flex flex-column justify-content-start"
          >
            <div className="mb-4 fade-in">
              <h4
                className="fw-bold"
                style={{ color: "#ff6300", fontSize: "1.5rem" }}
              >
                Businesses
              </h4>
              <p className="h4" style={{ color: "#010c54" }}>
                Businesses aiming to reduce their reliance on highly skilled
                resources.
              </p>
            </div>

            <div className="mb-4 fade-in">
              <h5
                className="fw-bold"
                style={{ color: "#ff6300", fontSize: "1.5rem" }}
              >
                Startups and Enterprises
              </h5>
              <p className="h4" style={{ color: "#010c54" }}>
                Startups and Enterprises looking for fast, scalable solutions.
              </p>
            </div>

            <div className="fade-in">
              <h5
                className="fw-bold"
                style={{ color: "#ff6300", fontSize: "1.5rem" }}
              >
                Organizations
              </h5>
              <p className="h4" style={{ color: "#010c54" }}>
                Organizations embracing agile development practices to meet
                evolving industry demands.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhoCanBenefit;
