import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import TTIPLLOGO2 from "../../Images/products/talent_takeaways.png";
import logo from "../../Images/products/Talent-Takeaways-Logomark.png";
const NavbarLanding = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleClick = () => {
    navigate("/");
  };

  const handleLinkClick = (isDropdown = false) => {
    if (!isDropdown) {
      setIsCollapsed(false);
    }
  };

  return (
    <Navbar
      expand="md"
      style={{
        backgroundColor: "transparent",
        padding: "10px 20px",
        color: isMobile ? "white" : "white",
        paddingBottom: isMobile ? "8px" : "",
        marginBottom: isMobile ? "10px" : "",
      }}
      expanded={isCollapsed}
    >
      {isMobile || props.navbar ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={handleClick}
            src={TTIPLLOGO2}
            style={{ cursor: "pointer", width: "120px", height: "auto" }}
            alt="logo"
          />
        </div>
      ) : null}

      <Navbar.Toggle
        aria-controls="navbarScroll"
        style={{
          border: "none",
          backgroundColor: "white",
          color: "white",
          padding: "5px",
          borderRadius: "5px",
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "black",
            margin: "4px 0",
          }}
        />
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "black",
            margin: "4px 0",
          }}
        />
        <span
          style={{
            display: "block",
            width: "22px",
            height: "2px",
            backgroundColor: "black",
            margin: "4px 0",
          }}
        />
      </Navbar.Toggle>

      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="m-auto mt-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobile ? "100%" : "75%",
            backgroundColor: isMobile
              ? "rgba(255, 255, 255, 0.33)"
              : "transparent",
            color: isMobile ? "#010c54" : "white",
            paddingTop: isMobile ? "10px" : "",
            borderRadius: isMobile ? "12px" : "0",
            boxShadow: isMobile ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
            backdropFilter: isMobile ? "blur(20px)" : "none",
          }}
        >
          <Link
            to="/"
            onClick={() => handleLinkClick()}
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              color: isMobile ? "white" : "white",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            HOME
          </Link>

          <div
            style={{
              position: "relative",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <Link
              to="/products"
              onClick={(e) => {
                e.preventDefault();
                navigate("/products");
              }}
              style={{
                textDecoration: "none",
                color: isMobile
                  ? "white"
                  : pathname.split("/")[1] === "products"
                  ? "#f44336"
                  : "white",
                fontWeight: "bold",
                marginBottom: isMobile ? "10px" : "",
              }}
            >
              PRODUCTS
            </Link>

            {showDropdown && (
              <ul
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  marginTop: "5px",
                  backgroundColor: "#fff",
                  listStyle: "none",
                  padding: "10px 0",
                  width: "200px",
                  boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
                  zIndex: 1000,
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                {[
                  { label: "LMS", link: "/LMS" },
                  { label: "EDMS", link: "/EDMS" },
                  { label: "EAMS", link: "/EAMS" },
                  { label: "SRM", link: "/SRM" },
                  { label: "HRMS", link: "/HRMS" },
                  { label: "LIMS", link: "/LIMS" },
                ].map((item, index) => (
                  <li key={index} style={{ margin: "0" }}>
                    <Link
                      to={item.link}
                      onClick={() => handleLinkClick(true)}
                      style={{
                        padding: "10px 15px",
                        display: "block",
                        color: "#010c54",
                        textDecoration: "none",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        transition: "all 0.3s ease-in-out",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.paddingLeft = "25px";
                        e.target.style.color = "#f44336";
                        e.target.style.backgroundColor = "#f7f7f7";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.paddingLeft = "15px";
                        e.target.style.color = "#010c54";
                        e.target.style.backgroundColor = "transparent";
                      }}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <Link
            to="/zoho-implementation"
            onClick={() => handleLinkClick()}
            style={{
              textDecoration: "none",
              color: isMobile ? "white" : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            DIGITAL MARKETING
          </Link>

          <Link
            to="/#OurServices"
            onClick={handleLinkClick}
            style={{
              textDecoration: "none",
              color: isMobile ? "white" : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            OUR SERVICES
          </Link>
          <Link
            to="/#HowWedo"
            onClick={handleLinkClick}
            style={{
              textDecoration: "none",
              color: isMobile ? "white" : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            WHAT WE DO
          </Link>
          <Link
            to="/#OurClients"
            onClick={handleLinkClick}
            style={{
              textDecoration: "none",
              color: isMobile ? "white" : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            OUR CLIENTS
          </Link>
          <Link
            to="/about"
            onClick={handleLinkClick}
            style={{
              textDecoration: "none",
              color:
                pathname.split("/")[1] === "about"
                  ? "#f44336"
                  : isMobile
                  ? "white"
                  : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            ABOUT US
          </Link>
          <Link
            to="/career"
            onClick={handleLinkClick}
            style={{
              textDecoration: "none",
              color: isMobile ? "white" : "white",
              fontWeight: "bold",
              margin: "0 10px",
              paddingBottom: isMobile ? "10px" : "",
            }}
          >
            CAREERS
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarLanding;
