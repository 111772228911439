import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Integrated from "../../../../src/Images/products/srm/ProcurementPlanning.gif";
import Vendor from "../../../../src/Images/products/srm/VendorManagement.gif";
import Tendering from "../../../../src/Images/products/srm/TenderingBidding.gif";

import Workflow from "../../../../src/Images/products/srm/WorkflowAutomation.gif";
import Contract from "../../../../src/Images/products/srm/ContractManagement.gif";
import Asset from "../../../../src/Images/products/srm/AssetInventory.gif";

import Audit from "../../../../src/Images/products/srm/AuditReadiness.gif";
import Reporting from "../../../../src/Images/products/srm/ReportingAnalytics.gif";
import Integration from "../../../../src/Images/products/srm/Integration.gif";

const FeatureOfSrm = () => {
  const features = [
    {
      name: "Integrated Procurement Planning",
      img: Integrated,
      content:
        "Integrated Procurement Planning: Centralized platform for creating and managing procurement plans",
      subcontent:
        "Budget Control: Real-time tracking of expenditures against allocated budgets.",
    },
    {
      name: "Vendor Management ",
      img: Vendor,
      content:
        "Vendor Registration Portal: Seamless onboarding with automated KYC and compliance checks",
      subcontent:
        "Vendor Performance Metrics Real-time tracking of expenditures against allocated budgets",
    },
    {
      name: "Tendering and Bidding",
      img: Tendering,
      content:
        "Automated Tender Creation: Pre-configured templates for RFPs, RFQs, and EOIs.",
      subcontent:
        " E-Tendering Platform Secure online portal for publishing and managing tenders.",
    },
    {
      name: "Procurement Workflow Automation",
      img: Workflow,
      content:
        "Approval Workflow: Role-based routing for approvals with digital signatures. ",
      subcontent:
        "Milestone Tracking: Automated notifications for key procurement stages.",
    },
    {
      name: "Contract Management",
      img: Contract,
      content:
        "Dynamic Contract Templates: Tailored contracts derived directly from tender terms.",
      subcontent:
        "Contract Lifecycle Management: Creation, approval, and execution of contracts.",
    },
    {
      name: "Asset and Inventory Management",
      img: Asset,
      content:
        "Real-Time Updates: Synchronization with inventory systems to avoid overstocking or shortages.",
      subcontent:
        "Disposal Planning: Modules for asset lifecycle management, including disposal and auctioning",
    },
    {
      name: "Compliance and Audit Readiness",
      img: Audit,
      content:
        "Policy Adherence: Ensures procurement complies with government and organizational regulations",
      subcontent:
        "Audit Trails: Comprehensive records of all transactions and decisions.",
    },
    {
      name: "Reporting and Analytics ",
      img: Reporting,
      content:
        "Approval Workflow: Role-based routing for approvals with digital signatures. ",
      subcontent:
        "Milestone Tracking: Automated notifications for key procurement stages",
    },
    {
      name: "Integration Capabilities",
      img: Integration,
      content:
        "ERP Integration Seamless connection with SAP, Oracle, and other ERP systems",
      subcontent:
        "Payment Gateway Support: Integrated payment processing for seamless transactions",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5  pt-4">
      <div className="container text-center my-2">
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            E-Procurement Features
          </p>
        </div>
        <div className="row gy-4">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center bt-3"
            >
              <div
                className="card shadow-md"
                style={{ width: "20rem", borderTop: "8px solid #ff6300 " }}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "70%",
                      height: "auto",
                      padding: "10%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div className="card-body">
                  <p
                    className="card-title  fw-bold pb-2 h4"
                    style={{ textAlign: "center", color: "#ff6300 " }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "left" }}
                  >
                    {item.content}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "left" }}
                  >
                    {item.subcontent}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureOfSrm;
