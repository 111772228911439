import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "./style.module.css";
import Assets from "../../Config/Assets";
import HeroContent from "./HeroContent";
import Customers from "./Customers";
import GetReadyToStart from "./GetReadyToStart";
import ProductList from "./ProductList";
import Application from "./Application";
import ProductReview from "./ProductReview";
import dummy from "../../Images/dummy.png";
import dum from "../../Images/42.png";
import "../Products/style.css";
import { useMediaQuery } from "react-responsive";
const Products = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span
                          style={{ fontSize: isMobile ? "20px" : "40px" }}
                          className="text-uppercase"
                        >
                          TTIPL Products: Empowering your business with the
                          flexibility it requires, while providing the seamless
                          simplicity that IT teams demand.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <HeroContent />
      <ProductList />
      <Application />
      <Customers />
      <ProductReview />
      <GetReadyToStart />
    </div>
  );
};

export default Products;
