import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import dummy from "../../../Images/products/eams/eamss.png";
import demo from "../../../Images/products/eams/eams.mp4";
export const EamsHero = () => {
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div>
      <Container className="py-5">
        <Row className="align-items-start flex-column-reverse flex-sm-row">
          {/* Text Column */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 justify-content-around px-lg-4"
          >
            <div className="pb-1 ">
              <div>
                <p
                  className="display-4"
                  style={{ color: "#ff6300", fontWeight: "bold" }}
                >
                  Enterprise Asset Management System
                </p>
              </div>
            </div>

            <p
              className="pb-4 h4 fw-bold pt-lg-2"
              style={{ textAlign: "justify", color: "#010c54" }}
            >
              An advanced Enterprise Asset Management System (EAMS) streamlines
              asset tracking, maintenance, and lifecycle management, providing a
              trusted solution for optimized operations. Eliminate
              inefficiencies, unexpected downtime, and asset mismanagement with
              this comprehensive platform. Offering secure, real-time access to
              data from anywhere, EAMS ensures operational excellence, enhances
              asset reliability, reduces costs, and supports a more efficient,
              sustainable enterprise.
            </p>
          </Col>

          {/* Image Column */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="mb-3 d-flex justify-content-center align-items-center position-relative"
          >
            {!isPlaying ? (
              <div
                onClick={handlePlayVideo}
                style={{
                  height: "400px",
                  width: "100%",
                  backgroundImage: `url(${dummy})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaPlay />
                </div>
              </div>
            ) : (
              <video
                controls
                className="img-fluid rounded"
                style={{
                  height: "400px",
                  width: "100%",
                }}
                alt="Cloudera Data Platform"
              >
                <source src={demo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
