import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Assessments from "../../../../src/Images/products/lms/course.gif";
import Convenient from "../../../../src/Images/products/lms/user.gif";
import Personalized from "../../../../src/Images/products/lms/content.gif";
import Measurable from "../../../../src/Images/products/lms/assessment.gif";
import Accessible from "../../../../src/Images/products/lms/communication.gif";
import Resources from "../../../../src/Images/products/lms/report.gif";

const FeaturesOfLMS = () => {
  const features = [
    {
      name: "Course Management",
      img: Assessments,
      content: "Create, organize, and manage courses effortlessly.",
      subcontent: "Upload materials, set deadlines, and track progress.",
    },
    {
      name: "User Management",
      img: Convenient,
      content: "Efficiently manage user accounts and enrollments.",
      subcontent: "Control access permissions for various courses.",
    },
    {
      name: "Learning Content Management",
      img: Personalized,
      content:
        "Support for text, videos, audio, presentations, and interactive modules",
      subcontent: " Deliver engaging and varied learning materials.",
    },
    {
      name: "Assessment and Grading",
      img: Measurable,
      content: "Create and assign quizzes, tests, and assignments",
      subcontent: "Automated grading and feedback for quick learner response.",
    },
    {
      name: "Communication Tools",
      img: Accessible,
      content:
        "Engage with discussion forums, messaging systems, and virtual classrooms.",
      subcontent: "Foster collaboration between learners and instructors.",
    },
    {
      name: "Reporting and Analytics",
      img: Resources,
      content:
        "Access detailed reports on learner progress and course completion.",
      subcontent: "Analyze performance to improve learning outcomes.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="pb-5  pt-4">
      <div className="container text-center my-2">
        <div>
          <p
            style={{ color: "#ff6300", fontWeight: "bold" }}
            className="display-4 d-flex flex-column justify-content-center align-items-center text-center my-5 py-4"
          >
            Features of LMS
          </p>
        </div>
        <div className="row gy-4">
          {features.map((item, index) => (
            <div
              key={index}
              className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center bt-3"
            >
              <div
                className="card shadow-md"
                style={{ width: "20rem", borderTop: "8px solid #ff6300 " }}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="card-img-top"
                    style={{
                      width: "70%",
                      height: "auto",
                      padding: "10%",
                      maxHeight: "180px",
                      objectFit: "contain",
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </div>
                <div className="card-body">
                  <p
                    className="card-title  fw-bold pb-2 h4"
                    style={{ textAlign: "center", color: "#ff6300 " }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "left" }}
                  >
                    {item.content}
                  </p>
                  <p
                    className="card-text text-left h5 fw-bold"
                    style={{ textAlign: "left" }}
                  >
                    {item.subcontent}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesOfLMS;
