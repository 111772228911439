import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import hrms from "../../Images/Human Resources management.png";
import srm from "../../Images/Supplier Relationship Management.png";
import edms from "../../Images/products/edms/edmsss.jpg";
import lims from "../../Images/Laboratory Information Management System.png";
import eams from "../../Images/products/eams/emasss.jpg";
import lms from "../../Images/products/lms/lmssss.jpg";

const ProductList = () => {
  const navigate = useNavigate();

  const productList = [
    {
      img: lms,
      name: "E-Learning Management System (LMS)",
      content:
        "A comprehensive E-Learning solution designed for modern educational institutions and corporate training, offering efficient online learning management, engagement tools, and seamless integration.",
      route: "/LMS",
    },
    {
      img: edms,
      name: "Electronic Document Management System (EDMS)",
      content:
        "A secure document management system providing efficient storage, approval workflows, and e-signing capabilities, ensuring easy access, top-tier security, and a paperless, cost-effective solution.",
      route: "/EDMS",
    },
    {
      img: eams,
      name: "Enterprise Asset Management System (EAMS)",
      content:
        "A smart asset management solution leveraging domain expertise and innovative strategies to drive efficiency, streamline operations, and maximize asset value.",
      route: "/EAMS",
    },
    {
      img: srm,
      name: "Supplier Relationship Management (SRM)",
      content:
        "A centralized solution to optimize supplier relationships, eliminate data silos, and ensure secure and efficient collaboration across business functions.",
      route: "/SRM",
    },
    {
      img: lims,
      name: "Laboratory Information Management System (LIMS)",
      content:
        "An advanced LIMS designed to enhance lab operations with streamlined data management, improved traceability, and compliance with industry standards.",
      route: "/LIMS",
    },
    {
      img: hrms,
      name: "Human Resource Management System (HRMS)",
      content:
        "A centralized platform for effective workforce management, streamlining HR processes such as recruitment, performance tracking, and employee lifecycle management.",
      route: "/HRMS",
    },
  ];

  return (
    <div className="mx-4">
      <Container>
        <p
          style={{ color: "#ff6300", fontWeight: "bold" }}
          className="display-4 text-center my-5 py-4"
        >
          Our Products
        </p>

        {productList.map((item, index) => (
          <Row
            key={index}
            className={`mb-5 d-flex align-items-start ${
              index % 2 === 0 ? "flex-md-row" : "flex-md-row-reverse"
            }`}
          >
            <Col xs={12} md={4} className="mb-md-0">
              <img
                src={item.img}
                alt={item.name}
                className="img-fluid rounded"
                style={{
                  transition: "transform 0.3s ease",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              />
            </Col>
            <Col xs={12} md={8}>
              <div
                className="d-flex flex-column h-100 justify-content-between"
                style={{ alignItems: "flex-start" }}
              >
                <p className="h2 fw-bold" style={{ color: "#ff6300" }}>
                  {item.name}
                </p>
                <div
                  style={{
                    backgroundColor: "#ff6300",
                    height: "3px",
                    width: "10%",
                    marginBottom: "10px",
                  }}
                ></div>
                <p
                  className="h4 fw-bold justify-text"
                  style={{ textAlign: "justify", color: "#010c54" }}
                >
                  {item.content}
                </p>
                <a
                  onClick={() => navigate(item.route)}
                  style={{
                    cursor: "pointer",
                    color: "#ff6300",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Learn More
                </a>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default ProductList;
