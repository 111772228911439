import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import rdmp from "../../Images/products/features/developemnet.gif";

const Application = () => {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "#F4FAFF" }} className="py-3">
      <Container>
        <div className="my-3 pt-2 text-center">
          <p
            className="display-4 my-5 py-2"
            style={{ fontWeight: "bold", color: "#ff6300" }}
          >
            Application
          </p>
        </div>
        <Row className="g-6 pb-5 justify-content-center">
          <Col xs={12} md={8} lg={12} className="text-center px-lg-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="h2 fw-bold mb-3" style={{ color: "#010c54" }}>
                Rapid Development Methodology Platform
              </p>
              <p
                className="h4 fw-bold text-center"
                style={{ textAlign: "center", color: "black" }}
              >
                RDMP is a cutting-edge platform revolutionizing application
                development. Powered by ReactJS and NodeJS, it optimizes
                workflows across industries, reducing time and costs while
                ensuring superior quality.
              </p>
              <span
                style={{
                  color: "#ff6300",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="fw-bold h4"
                onClick={() => navigate("/rdmp")}
              >
                Learn More
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Application;
