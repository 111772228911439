import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Process = () => {
  return (
    <div className="process-section py-5 bg-light">
      <Container>
        <div className="text-center mb-5">
          <h1 className="display-5 fw-bold " style={{ color: "#ff6300" }}>
            E- Procurement Process Framework Overview
          </h1>
        </div>
        <div className="d-flex align-items-center mb-5">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle me-3 fw-bold"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#ff6300",
              color: "#fff",
            }}
          >
            1
          </div>
          <h4 className="h4 fw-bold mb-0" style={{ color: "#010c54" }}>
            Planning Process Features
          </h4>
        </div>
        <Row className="g-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Needs Assessment
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Requirement identification and categorization.
                </li>
                <li className="mb-2">
                  Budget allocation and approval workflows.
                </li>
                <li>
                  Integration with inventory systems to check existing stock.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Procurement Planning
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">Annual procurement plan creation.</li>
                <li className="mb-2">Multi-year procurement forecasting.</li>
                <li>Prioritization and scheduling of procurements</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="g-4 mt-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Spend Analysis
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Historical data for expenditure patterns
                </li>
                <li className="mb-2">
                  Predictive analytics for better budget planning
                </li>
                <li>Compliance checks for procurement policies.</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Stakeholder Collaboration
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Real-time communication with departments.
                </li>
                <li className="mb-2">Collaborative approvals and reviews</li>
                <li>Role-based access for transparency.</li>
              </ul>
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center mb-5 pt-5">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle me-3 fw-bold"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#ff6300",
              color: "#fff",
            }}
          >
            2
          </div>
          <h4 className="h4 fw-bold mb-0" style={{ color: "#010c54" }}>
            Tendering process
          </h4>
        </div>
        <Row className="g-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Tender Creation
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Automated tender document generation (RFP, RFQ, etc.)
                </li>
                <li className="mb-2">
                  Template library for standardized documents.
                </li>
                <li>Specification wizard for detailed tender descriptions.</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Publishing
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Multichannel publication (portals, emails, public bulletins)
                </li>
                <li className="mb-2">
                  Notifications to pre-registered vendors.
                </li>
                <li>Scheduling tender announcements and reminders.</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="g-4 mt-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Vendor Management
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Pre-qualification checks and vendor registration.
                </li>
                <li className="mb-2">
                  Digital profiles and performance histories.
                </li>
                <li>Online vendor queries and clarification management.</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Bid Submission & Evaluation
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Automatic compliance checks against criteria.
                </li>
                <li className="mb-2">
                  Weighted scoring systems for bid evaluation.
                </li>
                <li>AI-based analysis for optimal vendor selection.</li>
              </ul>
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center mb-5 pt-5">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle me-3 fw-bold"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#ff6300",
              color: "#fff",
            }}
          >
            3
          </div>
          <h4 className="h4 fw-bold mb-0" style={{ color: "#010c54" }}>
            Contract Management Process Features
          </h4>
        </div>
        <Row className="g-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Contract Drafting
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Dynamic contract templates aligned with tender terms.
                </li>
                <li className="mb-2">Clause libraries for legal compliance.</li>
                <li>
                  Auto-fill data from tender documents and vendor submissions
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Approval Workflow
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Multi-level electronic signature integrations.
                </li>
                <li className="mb-2">
                  Collaboration tools for reviews and edits.
                </li>
                <li>Role-based access to sensitive contract data.</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="g-4 mt-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Monitoring & Compliance
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Key milestone and deliverable tracking.
                </li>
                <li className="mb-2">
                  Automatic notifications for deadlines and renewals.
                </li>
                <li>Performance tracking for vendors under the contract.</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Amendments & Termination
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Change request management for contract terms.
                </li>
                <li className="mb-2">Approval workflows for amendments.</li>
                <li>
                  Structured workflows for early terminations or disputes.
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center mb-5 pt-5">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle me-3 fw-bold"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#ff6300",
              color: "#fff",
            }}
          >
            4
          </div>
          <h4 className="h4 fw-bold mb-0" style={{ color: "#010c54" }}>
            Disposal Process Features
          </h4>
        </div>
        <Row className="g-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Needs Assessment
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Requirement identification and categorization.
                </li>
                <li className="mb-2">
                  Budget allocation and approval workflows.
                </li>
                <li>
                  Integration with inventory systems to check existing stock.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Supplier Selection
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Identifying and shortlisting potential suppliers.
                </li>
                <li className="mb-2">
                  Evaluating supplier proposals and quotations.
                </li>
                <li>Ensuring compliance with procurement policies.</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="g-4 mt-4">
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Contract Management
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">
                  Drafting and managing supplier contracts.
                </li>
                <li className="mb-2">
                  Monitoring contract performance and compliance.
                </li>
                <li>Ensuring timely renewals and amendments.</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="p-4 shadow-sm  rounded"
              style={{ backgroundColor: "#F4FAFF" }}
            >
              <h4 className="fw-bold" style={{ color: "#010c54" }}>
                Procurement Execution
              </h4>
              <div
                className="rounded"
                style={{
                  backgroundColor: "#ff6300",
                  padding: "2px",
                  width: "20%",
                }}
              ></div>
              <ul className="list-unstyled h5 fw-bold py-2">
                <li className="mb-2">Placing and tracking purchase orders.</li>
                <li className="mb-2">
                  Coordinating with logistics for timely delivery.
                </li>
                <li>
                  Ensuring quality checks and receiving goods efficiently.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
