import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dummy from "../../../Images/products/srm/srmDemo.mp4";
import demo from "../../../Images/products/srm/srmm.png";
import { FaPlay } from "react-icons/fa";
export const SrmHeroContent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  return (
    <Container className="py-5">
      <Row className="align-items-start flex-column-reverse flex-sm-row">
        {/* Text Column */}
        <Col
          lg={6}
          md={6}
          sm={12}
          className="mb-3 justify-content-around px-lg-4"
        >
          <div className="pb-3">
            <div>
              <p
                className="display-4"
                style={{ color: "#ff6300", fontWeight: "bold" }}
              >
                Supplier Relationship Management
              </p>
            </div>
          </div>

          <p
            className="pb-4 h4 fw-bold pt-lg-2"
            style={{ textAlign: "justify", color: "#010c54" }}
          >
            Supplier Relationship Management (SRM) is a strategic approach to
            managing and optimizing the interactions and collaborations between
            an organization and its suppliers. Our SRM solutions are designed to
            help businesses foster long-term partnerships, ensure supply chain
            stability, and drive mutual growth.
          </p>
        </Col>

        <Col
          lg={6}
          md={6}
          sm={12}
          className="mb-3 d-flex justify-content-center align-items-center position-relative"
        >
          {!isPlaying ? (
            <div
              onClick={handlePlayVideo}
              style={{
                height: "400px",
                width: "100%",
                backgroundImage: `url(${demo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "40px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaPlay />
              </div>
            </div>
          ) : (
            <video
              controls
              className="img-fluid rounded"
              style={{
                height: "400px",
                width: "100%",
              }}
              alt="Cloudera Data Platform"
            >
              <source src={dummy} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Col>
      </Row>
    </Container>
  );
};
